import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonQueryParamsFromGrid } from '../../../common-shared/classes/common-query-params-from-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InjectedOfferProductService } from '../../models/offer-products-model';
import { CommonGridImageComponent } from '../../../common-grid-component/components/common-grid-image/common-grid-image.component';
import { CommonGridToggleComponent } from '../../../common-grid-component/components/common-grid-toggle/common-grid-toggle.component';
import { fromCentsToEuro } from '../../../../config';


@Component({
  selector: 'lib-common-offer-products-list',
  templateUrl: './common-offer-products-list.component.html',
  styleUrls: ['./common-offer-products-list.component.scss']
})
export class CommonOfferProductsListComponent extends CommonQueryParamsFromGrid implements OnInit {

  @Input() offerProductsService: InjectedOfferProductService;
  @Input() id: string;
  @Output() editEmitter = new EventEmitter();
  @Output() addEmitter = new EventEmitter();
  @Output() deleteEmitter = new EventEmitter();

  constructor(protected router: Router, protected activatedRouter: ActivatedRoute, private translate: TranslateService) {
    super(router, activatedRouter);
  }

  ngOnInit(): void {
    this.turnOffAutoLoading();
    this.initGridCustomization();
    this.initGridChanges();
  }

  loadData(): void {
    this.loading = true;
    this.subs.push(this.offerProductsService.getOfferProducts(this.id, this.filteringObj).subscribe((res: any) => {
      this.filteringObj.setPager(
        res.meta.currentPage,
        res.meta.pageCount,
        res.meta.perPage,
        res.meta.total
      );
      this.source.load(res.data).then(() => {
        this.loading = false;
      });
    }), err => {
      this.loading = false;
    });
  }

  setColumns(): void {
    this.settings = {
      pager: false,
      localStorageId: 'offer-products-merchant',
      mode: 'external',
      edit: {
        editButtonContent: '<i class="far fa-edit"></i>',
      },
      add: {
        addButtonContent: '<i class="fa fa-plus"></i>',
      },
      actions: {
        columnTitle: this.translate.instant('common.offerProductGrid.edit'),
        delete: false
      },
      columns: {
        id: {
          title: this.translate.instant('common.offerProductGrid.id'),
          type: 'text',
          filter: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        photo: {
          title: this.translate.instant('common.offerProductGrid.photo'),
          type: 'custom',
          filter: false,
          renderComponent: CommonGridImageComponent,
          onComponentInitFunction: (instance) => {
            instance.key = 'photoUrlMedium';
          },
        },
        name: {
          title: this.translate.instant('common.offerProductGrid.name'),
          type: 'text',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'category*name': {
          title: this.translate.instant('common.offerProductGrid.category'),
          type: 'text',
          valuePrepareFunction: (cell, row) => {
            return row.category?.name;
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'price*priceWithVat': {
          title: this.translate.instant('common.offerProductGrid.priceWithVat'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return fromCentsToEuro(row.price.priceWithVat);
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'price*priceExcludingVat': {
          title: this.translate.instant('common.offerProductGrid.priceExcludingVat'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return fromCentsToEuro(row.price.priceExcludingVat);
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'price*vat': {
          title: this.translate.instant('common.offerProductGrid.vat'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return row.price.vat;
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        menuOptions: {
          title: this.translate.instant('common.offerProductGrid.optionsGroup'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return cell.length > 0 ? [...cell.map(item => item.name)] : '-';
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        description: {
          title: this.translate.instant('common.offerProductGrid.description'),
          type: 'text',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        isAvailable: {
          title: this.translate.instant('common.offerProductGrid.available'),
          type: 'custom',
          renderComponent: CommonGridToggleComponent,
          filter: {
            type: 'list',
            config: {
              selectText: this.translate.instant('common.grid.select'),
              list: [
                {value: true, title: this.translate.instant('adminGrid.select_items.true')},
                {value: false, title: this.translate.instant('adminGrid.select_items.false')},
              ]
            }
          },
          onComponentInitFunction: (instance) => {
            instance.key = 'isAvailable';
            instance.changeMethod.subscribe(event => {
              this.offerProductsService.updateProductAvailability(this.id, event.data.id, !event.data.isAvailable)
                .subscribe(() => {
                });
            });
          }
        }
      }
    };
  }

  edit(event: any) {
    this.editEmitter.emit(event);
  }

  addNewProduct(event: any) {
    this.addEmitter.emit(event);
  }
}
