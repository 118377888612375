import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonBackButtonHistoryDirective } from './directives/common-back-button-history.directive';
import { CommonBackbuttonHistoryService } from './services/common-backbutton-history.service';


@NgModule({
  declarations: [
    CommonBackButtonHistoryDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonBackButtonHistoryDirective
  ],
})
export class CommonBackbuttonHistoryModule {
  static forRoot(): ModuleWithProviders<CommonBackbuttonHistoryModule> {
    return {
      ngModule: CommonBackbuttonHistoryModule,
      providers: [{provide: CommonBackbuttonHistoryService}],
    };
  }
}
