<ng-container [ngSwitch]="value?.openType">
  <nb-card nbButton *ngSwitchCase="OPEN_TYPE.AUTO" class="clickable-card m-0 p-1" (click)="pauseClicked()">
    <nb-card-body>
      <div class="text-center mb-2">
        {{'common.offer.openInfo.automatic.label' | translate | uppercase}}
      </div>
      <ng-container *ngIf="value.isGlobalOpen">
        <div class="text-center text-success">
          {{'common.offer.openInfo.open' | translate}}
        </div>
      </ng-container>
      <ng-container *ngIf="!value.isGlobalOpen">
        <div class="text-center text-danger">
          {{'common.offer.openInfo.closed' | translate}}
        </div>
      </ng-container>
    </nb-card-body>
  </nb-card>

  <nb-card *ngSwitchCase="OPEN_TYPE.MANUAL" class="m-0 p-1">
    <nb-card-body>
      <div class="text-center mb-2">
        {{'common.offer.openInfo.manual.label' | translate | uppercase}}
      </div>
      <div class="text-center">
        <nb-toggle class="m-auto" (checkedChange)="toggleChanged($event)" [checked]="value.isOpen"></nb-toggle>
      </div>
    </nb-card-body>
  </nb-card>

  <nb-card *ngSwitchCase="OPEN_TYPE.VACATION" class="m-0 p-1">
    <nb-card-body>
      <div class="text-center mb-2">
        {{'common.offer.openInfo.vacation.label' | translate | uppercase}}
      </div>
      <div class="text-center">
        {{'common.offer.openInfo.vacationUntil' | translate}}
        <span class="text-danger">{{value?.vacationEnd | date : 'short'}}</span>
      </div>
    </nb-card-body>
  </nb-card>

  <nb-card nbButton *ngSwitchCase="OPEN_TYPE.PAUSE" class="clickable-card m-0 p-1" (click)="pauseClicked()">
    <nb-card-body>
      <div class="text-center mb-2">
        {{'common.offer.openInfo.paused.label' | translate | uppercase}}
      </div>
      <div class="text-center">
        {{'common.offer.openInfo.pausedUntil' | translate}}
        <span class="text-warning">{{value?.pauseUntil | date : 'short'}}</span>
      </div>
    </nb-card-body>
  </nb-card>
</ng-container>
