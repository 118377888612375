import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[CommonControlErrorContainer]'
})
export class CommonControlErrorContainerDirective {

  constructor(public vcr: ViewContainerRef) {
  }

}
