import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PATTERNS } from '../../../../config';
import { TranslateService } from '@ngx-translate/core';
import { LoginFormData } from '../../models/common-auth-models';

@Component({
  selector: 'lib-common-auth-login',
  templateUrl: './common-auth-login.component.html',
  styleUrls: ['./common-auth-login.component.scss']
})
export class CommonAuthLoginComponent implements OnInit {

  loginForm: FormGroup;
  submitIsLoading: boolean;
  @Input() headerTile: string;
  @Output() onSubmitEmitter = new EventEmitter<LoginFormData>();
  customErrors = {pattern: this.translateService.instant('commonLib.error.emailPattern')};

  constructor(private fb: FormBuilder, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.initLoginForm();
  }

  /**
   * init login form
   */

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(PATTERNS.email)]],
      password: ['', Validators.required]
    });
  }

  /**
   * emit data from form when user submit login
   */
  submitForm() {
    this.onSubmitEmitter.emit(this.loginForm.getRawValue());
  }
}
