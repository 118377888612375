import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import {
  JWT_ACCESS_STORAGE_MERCHANT_BO,
  JWT_REFRESH_TOKEN_STORAGE_MERCHANT_BO,
  USER_DATA_STORAGE_MERCHANT_BO
} from '../config';
import { UserModel, UserResponse } from 'common-lib';
import { InjectedAuthService } from 'common-lib';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends InjectedAuthService {

  public user$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);

  constructor(private http: HttpClient,
              private router: Router) {
    super();
  }

  getAuthToken() {
    return localStorage.getItem(JWT_ACCESS_STORAGE_MERCHANT_BO);
  }

  /**
   *
   * Set access and refresh token
   * set user default from local storage, if no user in LS set as undefined
   */
  public initUser() {
    this.user$.next(localStorage.getItem(USER_DATA_STORAGE_MERCHANT_BO) ? JSON.parse(localStorage.getItem(USER_DATA_STORAGE_MERCHANT_BO))
      : null);
  }

  /**
   * Log into app
   * @param email
   * @param password
   */
  public logIn(email: string, password: string): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.url}/merchant/auth/login`,
      {email, password}).pipe(tap(
      response => {
        this.updateUserDataWithTokens(response.user,
          response.accessToken,
          response.refreshToken);
      }
    ));
  }

  /**
   * get customer data from BE and customer  in app
   */
  public getUserMe() {
    this.http.get<UserModel>(`${environment.url}/merchant/auth/user`).subscribe(
      user => {
        this.updateUserData(user);
      }
    );
  }

  /*/!**
   * change customer password
   *!/
  public changeUserPassword(oldPassword, newPassword): Observable<any> {
    return this.http.put(`${environment.backendUrl}/customer/account/change-password`, {oldPassword, newPassword});
  }

  /!**
   * request to reset password
   * @param email: use email to send link to set new password
   *!/
  public resetPasswordRequest(email): Observable<any> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-reset/customer/request`,
      {email});
  }

  /!**
   * set new password
   * @param token
   * @param newPassword
   *!/

  public setNewPassword(token, newPassword): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-reset/customer/confirm`,
      {token, newPassword}).pipe(
      tap(res => {
        this.updateUserDataWithTokens(res.user, res.accessToken, res.refreshToken);
      })
    );
  }*/

  /**
   * update access token in app
   * @param newToken: new accessToken
   */
  private updateAccessToken(newToken: string) {
    localStorage.setItem(JWT_ACCESS_STORAGE_MERCHANT_BO, newToken);
  }

  /**
   * update refreshToken in app
   * @param newToken: new refreshToken
   */
  private updateRefreshToken(newToken: string) {
    localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE_MERCHANT_BO, newToken);
  }

  /**
   * Update user data in app
   * @param user: new user
   */
  private updateUserData(user: UserModel) {
    this.user$.next(user);
    localStorage.setItem(USER_DATA_STORAGE_MERCHANT_BO, JSON.stringify(user));
  }

  /**
   * Update user information and tokens
   *
   * @param user: user data, UserModel
   * @param accessToken: accessToken
   * @param refreshToken: refreshToken
   */
  private updateUserDataWithTokens(user, accessToken, refreshToken) {
    this.updateUserData(user);
    this.updateAccessToken(accessToken);
    this.updateRefreshToken(refreshToken);
  }

  /**
   * logout user from app
   */
  public logOutUser() {
    localStorage.removeItem(JWT_ACCESS_STORAGE_MERCHANT_BO);
    localStorage.removeItem(JWT_REFRESH_TOKEN_STORAGE_MERCHANT_BO);
    localStorage.removeItem(USER_DATA_STORAGE_MERCHANT_BO);
    this.user$.next(null);
    this.router.navigate(['/', 'auth']);
  }

  /**
   * refresh Tokens in app
   */
  public refreshTokens(): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.url}/merchant/auth/refresh`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      // map((res: any) => res.data),
      tap((res) => {
        this.updateUserDataWithTokens(res.user, res.accessToken, res.refreshToken);
      }));
  }


  /**
   * get access token from app
   */
  getAccessToken() {
    return localStorage.getItem(JWT_ACCESS_STORAGE_MERCHANT_BO);
  }

  /**
   * get refresh token from app
   */
  public getRefreshToken() {
    return localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE_MERCHANT_BO);
  }


  /**
   * check if user is logged in app
   */
  public isUserLogged() {
    return !!this.user$.getValue();
  }
}




