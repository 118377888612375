<div class="row">
  <div class="col-12">
    <lib-common-form-field-info
      [text]="'offerDetail.categories.desc_info'| translate">
    </lib-common-form-field-info>
  </div>
  <div [nbSpinner]="offerCategoriesIsLoading" class="col-md-8">
    <div class="row">
      <div class="col-12">
        <div class="single-part">
          {{'offerDetail.categories.allCategories' | translate | uppercase}}
        </div>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div *ngFor="let category of categories"
               cdkDrag class="category_item">
            <div class="row mx-0 px-0 no-gutters">
              <div class="col-12 my-2 d-flex">
                <div class="my-auto mr-3 dragging_cursor">
                  <i class="fas fa-bars"></i>
                </div>
                <input class="mr-4"
                       type="text"
                       fullWidth
                       nbInput
                       #nameInput="ngModel"
                       [(ngModel)]="category.name">
                <button class="mr-3 ml-0"
                        nbButton
                        status="success"
                        [disabled]="!(nameInput.dirty || nameInput.touched)"
                        [nbSpinner]="category.isUpdating"
                        (click)="updateCategory(category, nameInput)">
                  <i class="fas fa-check"></i></button>
                <button class="mr-0 ml-0"
                        nbButton
                        status="danger"
                        outline
                        [nbSpinner]="category.isDeleting"
                        (click)="removeCategory(category)">
                  <i class="far fa-trash-alt"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="wasDragged" class="d-flex justify-content-center py-2">
          <button type="button" nbButton status="primary"
                  (click)="saveOrder()"
                  [disabled]="isSavingOrder"
                  [nbSpinner]="isSavingOrder">
            {{'common.offer.saveUpdatedOrder' | translate}}
          </button>
        </div>
      </div>
      <div class="col-12">
        <div class="single-part">
          {{'offerDetail.categories.addNewCategory' | translate | uppercase}}
        </div>
        <div class="row my-2">
          <div class="col-12">
            <label class="label">
              {{'offerDetail.categories.newCategoryName' | translate}}
            </label>
          </div>
          <div class="col-12 d-flex">
            <input class="mr-4"
                   type="text"
                   fullWidth
                   placeholder="{{'offerDetail.categories.newCategoryName' | translate}}"
                   [formControl]="newCategoryFromControl"
                   nbInput>
            <button class="mr-0 ml-0" nbButton
                    status="primary"
                    [nbSpinner]="isCreatingNew"
                    [disabled]="newCategoryFromControl.invalid"
                    (click)="addCategory()">
              <i class="fas fa-plus"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-md-4">
    <ng-content>
    </ng-content>
  </div>
</div>
