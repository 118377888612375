import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAuthLayoutComponent } from './components/common-auth-layout/common-auth-layout.component';
import { NbLayoutModule } from '@nebular/theme';



@NgModule({
  declarations: [
    CommonAuthLayoutComponent
  ],
  imports: [
    CommonModule,
    NbLayoutModule
  ],
  exports: [
    CommonAuthLayoutComponent
  ]
})
export class CommonLayoutModule { }
