import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonBackbuttonHistoryService } from '../services/common-backbutton-history.service';

@Directive({
  selector: '[libCommonBackButtonHistory]'
})
export class CommonBackButtonHistoryDirective implements OnInit, OnDestroy {
  @Input('libCommonBackButtonHistory') defaultRoute: any[];
  @Input() secondaryButton: boolean;
  private rememberedRoutesForCurrentButton: string[];

  @HostBinding('style.display')
  get getSecondaryBackButton() {
    return this.secondaryButton ? 'none' : '';
  }

  constructor(private element: ElementRef,
              private routerMemoryService: CommonBackbuttonHistoryService,
              private router: Router) {
  }

  ngOnInit(): void {
    if (this.secondaryButton) {
      this.routerMemoryService.renderedSecondaryBackButtons.push(this);
    }
    // assign current remembered routes for current "back button"
    this.rememberedRoutesForCurrentButton = this.routerMemoryService.getCurrentRoutes();
    // remove last route which is actually current route(no need)
    this.rememberedRoutesForCurrentButton.pop();
    // in case user come to page and have no previous routes or defined default back route -> hide "back button" element
    if (this.rememberedRoutesForCurrentButton.length <= 0 && !this.defaultRoute) {
      this.element.nativeElement.style.display = 'none';
    }
  }

  ngOnDestroy(): void {
    if (this.secondaryButton) {
      this.routerMemoryService.renderedSecondaryBackButtons.pop();
    }
  }

  @HostListener('click') onClick() {
    // after click
    // if have some remembered route navigate to last
    if (this.routerMemoryService.renderedSecondaryBackButtons.length > 0) {
      this.routerMemoryService.renderedSecondaryBackButtons[this.routerMemoryService.renderedSecondaryBackButtons.length - 1].secondaryButtonClick();
    } else {
      this.navigateToLastRoute();
    }
  }

  secondaryButtonClick() {
    this.navigateToLastRoute();
  }

  navigateToLastRoute() {
    if (this.rememberedRoutesForCurrentButton.length > 0) {
      this.routerMemoryService.isBackButtonClicked = true;
      this.router.navigateByUrl(this.rememberedRoutesForCurrentButton[this.rememberedRoutesForCurrentButton.length - 1]).then(
        () => {
          this.afterRouteNavigate();
        }
      );
    }
    // if have no routes memory but have default route defined navigate to it
    else if (this.defaultRoute) {
      this.routerMemoryService.isBackButtonClicked = true;
      this.router.navigate(this.defaultRoute).then(() => {
        this.afterRouteNavigate();
      });

    }
  }

  afterRouteNavigate() {
    // after navigation end set is back button clicked to false
    this.routerMemoryService.isBackButtonClicked = false;
    // after route navigation back, remove last route from global memory
    // to avoid bad routes stack
    this.routerMemoryService.rememberedRoutes = this.rememberedRoutesForCurrentButton;
    // this.routerMemoryService.rememberedRoutes.pop();
  }
}
