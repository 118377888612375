import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonOfferPlanningComponent } from './components/common-offer-planning/common-offer-planning.component';
import {
  NbAccordionModule,
  NbAlertModule, NbCardModule,
  NbCheckboxModule, NbDatepickerModule, NbIconModule, NbRadioModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { NbMomentDateModule } from '@nebular/moment';
import { FromNumberToDaysPipe } from './pipes/from-number-to-days.pipe';
import { CommonFormModule } from '../common-form/common-form.module';
import { CommonOfferPauseDialogComponent } from './components/common-offer-pause-dialog/common-offer-pause-dialog.component';


@NgModule({
  declarations: [
    CommonOfferPlanningComponent,
    FromNumberToDaysPipe,
    CommonOfferPauseDialogComponent,
  ],
  imports: [
    CommonModule,
    NbAlertModule,
    TranslateModule,
    NbCheckboxModule,
    NbAccordionModule,
    CommonFormModule,
    NbTimepickerModule,
    NbMomentDateModule,
    NbDatepickerModule,
    NbRadioModule,
    NbCardModule,
    NbIconModule
  ],
  exports: [
    CommonOfferPlanningComponent
  ]
})
export class CommonOfferPlanningModule {
}
