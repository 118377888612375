import { Observable } from 'rxjs';
import { OfferModel } from '../../common-offer/models/injectedOfferServiceModel';
import { OFFER_PAUSE } from '../../common-shared/models/order-type';

export abstract class InjectedOfferPlanningService {
  abstract getOpeningTimes(offerId: string): Observable<any>;

  abstract updateAutoPlanning(offerId: string, isAutoPlanning: boolean): Observable<OfferModel>;

  abstract updateManualOpen(offerId: string, isOpen: boolean): Observable<OfferModel>;

  abstract updateOpeningHours(offerId: string, openingHours: OpeningHoursModel[]): Observable<OpeningHoursModel[]>;

  abstract updateOfferPause(offerId: string, offerPause: OfferPauseModel): Observable<OfferModel>;

  abstract deleteOfferPause(offerId: string): Observable<OfferModel>;

  abstract getOfferNextOpen(offerId: string): Observable<OfferNextOpenModel>;
}

export interface OpeningHoursModel {
  day: number;
  isAvailable: boolean;
  times: [{ start: any, end: any }, { start: any, end: any }];
}

export interface OfferPauseModel {
  type: OFFER_PAUSE;
  minutes: number;
  datetime: string;
}

export interface OfferNextOpenModel {
  nextOpen: string;
}
