import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonSubscriber } from '../../../common-shared/classes/common-subscriber';
import { Allergen, InjectedAllergenService } from '../../models/allergens-model';
import { InjectOfferCategoryService } from '../../../common-offer/models/injected-offer-category-service-model';
import { InjectedOfferGroupService } from '../../../common-offer-group-option/models/injected-offer-group-service';
import { InjectedOfferProductService, OfferProductModel, ProductFormModel } from '../../models/offer-products-model';
import { fromCentsToEuro } from '../../../../config';

@Component({
  selector: 'lib-common-offer-product-form',
  templateUrl: './common-offer-product-form.component.html',
  styleUrls: ['./common-offer-product-form.component.scss']
})
export class CommonOfferProductFormComponent extends CommonSubscriber implements OnInit {

  public productForm: FormGroup;
  public categories: { name: string, id: string }[];
  public allergens: Allergen[];
  public groups: { id: string, name: string }[];
  public productImageUrl;
  public formIsLoading: boolean;
  public submitIsLoading: boolean;
  public photoUploadIsLoading: boolean;
  public allergensIsLoading: boolean;
  public categoriesIsLoading: boolean;
  public optionsGroupIsLoading: boolean;
  public deleteIsLoading: boolean;
  public productId: string;
  @Input() offerId: string;
  @Input() isEdit: boolean;
  @Input() injectedAllergenService: InjectedAllergenService;
  @Input() injectedCategoryService: InjectOfferCategoryService;
  @Input() injectedOptionGroupService: InjectedOfferGroupService;
  @Input() injectedOfferProductService: InjectedOfferProductService;

  @Input('productData') set setProductData(data: OfferProductModel) {
    if (data) {
      if (data.id) {
        this.productId = data.id;
      }
      this.initProductForm();
      this.fillProductForm(data);
    }
  }

  @Output() submitEmitter = new EventEmitter<ProductFormModel>();
  @Output() deleteEmitter = new EventEmitter<any>();

  constructor(private fb: FormBuilder, public translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.initProductForm();
    this.initAllergens();
    this.initCategory();
    this.initOptionGroup();
  }


  initProductForm() {
    if (!this.productForm) {
      this.productForm = this.fb.group({
        menuCategoryId: ['', Validators.required],
        name: ['', Validators.required],
        description: ['', Validators.required],
        price: ['', Validators.required],
        vat: ['', Validators.required],
        ingredients: ['', Validators.required],
        isAvailable: [false, Validators.required],
        allergens: [[]],
        menuOptionGroups: [[]],
        photo: [null]
      });
    }
  }

  fillProductForm(productData) {
    this.productForm.patchValue({
      ...productData,
      menuOptionGroups: productData.menuOptions.map(singleOption => singleOption.id),
      allergens: productData.allergens.map(singleOption => singleOption.code),
      menuCategoryId: productData.category.id,
      price: fromCentsToEuro(productData.price.priceWithVat)
    });
    if (productData.photoUrlMedium) {
      this.productImageUrl = productData.photoUrlMedium;
    }
  }

  /**
   * set photo to form to be ready for request for BE
   * set to image URL to see immediately in form
   * @param event
   */
  uploadPhoto(event) {
    const file = event.target.files[0];
    this.productForm.controls.photo.setValue(file);
    this.productImageUrl = URL.createObjectURL(file);
    const reader = new FileReader();
    reader.onload = e => this.productImageUrl = reader.result;
    reader.readAsDataURL(file);
  }

  initAllergens() {
    this.allergensIsLoading = true;
    this.subs.push(this.injectedAllergenService.getAllergens().subscribe(
      allergens => {
        this.allergens = allergens;
        this.allergensIsLoading = false;
      }, error => {
        this.allergensIsLoading = false;
      }
    ));
  }

  initCategory() {
    this.categoriesIsLoading = true;
    this.subs.push(this.injectedCategoryService.getOfferCategories(this.offerId).subscribe(
      res => {
        this.categories = res;
        this.categoriesIsLoading = false;
      }, error => {
        this.categoriesIsLoading = false;
      }
    ));
  }

  initOptionGroup() {
    this.optionsGroupIsLoading = true;
    this.subs.push(this.injectedOptionGroupService.getOptionGroups(this.offerId).subscribe(
      res => {
        this.groups = res;
        this.optionsGroupIsLoading = false;
      }, error => {
        this.optionsGroupIsLoading = false;
      }
    ));
  }

  submitForm() {
    this.submitEmitter.emit(this.productForm.getRawValue());
  }

  deleteProduct() {
    this.deleteEmitter.emit();
  }
}
