import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDayNightSwitchComponent } from './common-day-night-switch/common-day-night-switch.component';


@NgModule({
  declarations: [
    CommonDayNightSwitchComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonDayNightSwitchComponent
  ]
})
export class CommonDayNightSwitchModule {
}
