import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonGridToggleComponent } from './components/common-grid-toggle/common-grid-toggle.component';
import { NbCardModule, NbToggleModule } from '@nebular/theme';
import { CommonGridImageComponent } from './components/common-grid-image/common-grid-image.component';
import { CommonGridOfferOpenInfoComponent } from './components/common-grid-offer-open-info/common-grid-offer-open-info.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    CommonGridToggleComponent,
    CommonGridImageComponent,
    CommonGridOfferOpenInfoComponent
  ],
  imports: [
    CommonModule,
    NbToggleModule,
    NbCardModule,
    TranslateModule
  ],
  exports: [
    CommonGridToggleComponent,
  ]
})
export class CommonGridComponentModule {
}
