export const PATTERNS = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/
};


export const euroCurrencyDivider = 100;
export const euroSign = '€';

// TODO CREATE PIPE FOR CALCULATION
export const fromEuroToCents = (euro: number, withSign?: boolean): number | string => {
  return withSign ? `${Number(euro) * euroCurrencyDivider}${withSign ? euroSign : ''}` : Number(euro) * euroCurrencyDivider;
};

export const fromCentsToEuro = (cents: number, withSign?: boolean) => {
  return withSign ? `${Number(cents) / euroCurrencyDivider}${withSign ? euroSign : ''}` : Number(cents) / euroCurrencyDivider;
};


export const COMMON_GRID_ACTION_BUTTONS = {
  edit: {
    editButtonContent: '<i class="far fa-edit"></i>',
  },
  add: {
    addButtonContent: '<i class="fa fa-plus"></i>',
  },
  delete: {
    deleteButtonContent: '<i class="fas fa-times"></i>',
  },
};
