export enum TRANSLATE_CATEGORIES {
  ALL = '',
  MERCHANT = 'merchant',
  CUSTOMER = 'customer',
  DELIVERY = 'delivery',
  ERRORS = 'error',
  BO_ADMIN = 'bo_admin',
  BO_MERCHANT = 'bo_merchant',
  BACKEND = 'backend',
  CATEGORY_SHARED_MOBILE = 'shared_mobile',
  CATEGORY_SHARED_WEB = 'shared_web'
}

export interface TranslateModel {
    category: string;
    en: string;
    fr: string;
    id: string;
    isMarkedForDeletion: boolean;
    key: string;
}
