import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CommonOfferOptionGroupsListComponent
} from './components/common-offer-option-groups-list/common-offer-option-groups-list.component';
import {
  CommonOfferOptionGroupDetailComponent
} from './components/common-offer-option-group-detail/common-offer-option-group-detail.component';
import { CommonOfferOptionItemsListComponent } from './components/common-offer-option-items-list/common-offer-option-items-list.component';
import {
  CommonOfferOptionItemDetailComponent
} from './components/common-offer-option-item-detail/common-offer-option-item-detail.component';
import { SmartTableBackendWrapperModule } from 'smart-table-backend-wrapper';
import {
    NbCardModule,
    NbDialogService,
    NbIconModule,
    NbInputModule, NbTooltipModule,
    NbUserModule
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { CommonInputValidatorsModule } from '../common-input-validators/common-input-validators.module';
import { CommonFormModule } from '../common-form/common-form.module';


@NgModule({
  declarations: [
    CommonOfferOptionGroupsListComponent,
    CommonOfferOptionGroupDetailComponent,
    CommonOfferOptionItemsListComponent,
    CommonOfferOptionItemDetailComponent
  ],
    imports: [
        CommonModule,
        SmartTableBackendWrapperModule,
        NbCardModule,
        TranslateModule,
        CommonInputValidatorsModule,
        CommonFormModule,
        NbInputModule,
        NbUserModule,
        NbIconModule,
        NbTooltipModule
    ],
  exports: [
    CommonOfferOptionGroupsListComponent,
    CommonOfferOptionGroupDetailComponent,
    CommonOfferOptionItemsListComponent,
    CommonOfferOptionItemDetailComponent
  ],
  providers: [
    NbDialogService
  ]
})
export class CommonOfferOptionsModule {
}
