import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonOfferListComponent } from './components/common-offer-list/common-offer-list.component';
import { SmartTableBackendWrapperModule } from 'smart-table-backend-wrapper';
import { CommonOfferCategoriesComponent } from './components/common-offer-categories/common-offer-categories.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormModule } from '../common-form/common-form.module';


@NgModule({
  declarations: [
    CommonOfferListComponent,
    CommonOfferCategoriesComponent
  ],
  imports: [
    CommonModule,
    SmartTableBackendWrapperModule,
    DragDropModule,
    TranslateModule,
    CommonFormModule
  ],
  exports: [
    CommonOfferListComponent,
    CommonOfferCategoriesComponent
  ]
})
export class CommonOfferModule {
}
