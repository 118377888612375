import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonOfferProductsListComponent } from './components/common-offer-products-list/common-offer-products-list.component';
import { SmartTableBackendWrapperModule } from 'smart-table-backend-wrapper';
import { CommonOfferProductFormComponent } from './components/common-offer-product-form/common-offer-product-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormModule } from '../common-form/common-form.module';
import { NbIconModule } from '@nebular/theme';


@NgModule({
  declarations: [
    CommonOfferProductsListComponent,
    CommonOfferProductFormComponent
  ],
  imports: [
    CommonModule,
    SmartTableBackendWrapperModule,
    CommonFormModule,
    TranslateModule,
    NbIconModule,
  ],
  exports: [
    CommonOfferProductsListComponent,
    CommonOfferProductFormComponent
  ]
})
export class CommonOfferProductsModule {
}
