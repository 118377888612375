export interface OptionGroup {
  id: string;
  name: string;
  type: OPTION_GROUP_TYPE;
}


export enum OPTION_GROUP_TYPE {
  CUMULATIVE = 'cumulative',
  EXCLUSIVE = 'exclusive',
  SELECTIVE = 'selective',
}
