import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonBackButtonHistoryDirective } from '../directives/common-back-button-history.directive';

@Injectable()
export class CommonBackbuttonHistoryService {

  rememberedRoutes: string[] = [];
  isBackButtonClicked: boolean;
  renderedSecondaryBackButtons: CommonBackButtonHistoryDirective[] = [];

  constructor(private router: Router) {
    // subscribe to route changes, push every new route tou rememberedRoutes
    this.router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        // only if user do not clicked on back button save route
        if (!this.isBackButtonClicked) {
          if (this.rememberedRoutes.length > 0) {
            // split path url from queryParameter
            const urlPathWithoutQuery = res.url.split('?')[0];
            // split path url from fragments
            const urlPath = urlPathWithoutQuery.split('#')[0];
            const lastRememberedUrlPath = this.rememberedRoutes[this.rememberedRoutes.length - 1].split('?')[0];
            // if current and last url path is same, just rewrite last url
            if (urlPath === lastRememberedUrlPath) {
              this.rememberedRoutes[this.rememberedRoutes.length - 1] = res.url;
            } else {
              // if current and last url is different, add url to rememberedRoutes
              this.rememberedRoutes.push(res.url);
            }
          }
          // if its first route to remember
          else {
            this.rememberedRoutes.push(res.url);
          }
        }
      }
    });
  }

  /**
   * create new array from current rememberedRoutes
   */
  public getCurrentRoutes() {
    return Array.from(this.rememberedRoutes);
  }

  public resetRememberedRoutes() {
    this.rememberedRoutes = [];
  }
}
