import { Observable } from 'rxjs';
import { MERCHANT_ASSORTMENT, UserModel } from '../../common-shared/models/common-user';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { OPEN_TYPE, ORDER_TYPE } from '../../common-shared/models/order-type';

export abstract class InjectedOfferService {
  private assortmentsTranslates = {
    [MERCHANT_ASSORTMENT.SUPERMARKET]: marker('common.enum.assortmentTypes.supermarket'),
    [MERCHANT_ASSORTMENT.RESTAURANT]: marker('common.enum.assortmentTypes.restaurant')
  };

  private deliveryTypesTranslates = {
    [ORDER_TYPE.IMMEDIATE_PICKUP]: marker('common.enum.deliveryTypes.immediate_pickup'),
    [ORDER_TYPE.IMMEDIATE_DELIVERY]: marker('common.enum.deliveryTypes.immediate_delivery'),
    [ORDER_TYPE.ROUNDTRIP_DELIVERY]: marker('common.enum.deliveryTypes.roundtrip_delivery'),
    [ORDER_TYPE.ROUNDTRIP_PICKUP]: marker('common.enum.deliveryTypes.roundtrip_pickup'),
  };

  private openTypeTranslates = {
    [OPEN_TYPE.AUTO]: marker('common.enum.openTypes.auto'),
    [OPEN_TYPE.MANUAL]: marker('common.enum.openTypes.planning'),
    [OPEN_TYPE.VACATION]: marker('common.enum.openTypes.vacation'),
    [OPEN_TYPE.PAUSE]: marker('common.enum.openTypes.pause'),
  };

  protected constructor(private translateService: TranslateService) {
  }

  abstract getOffers(filteringObject?): Observable<any>;

  abstract getSingleOffer(id: string): Observable<OfferModel>;

  public getAssortmentTranslate(assortment: MERCHANT_ASSORTMENT) {
    if (assortment in this.assortmentsTranslates) {
      return this.translateService.instant(this.assortmentsTranslates[assortment]);
    }
    return assortment;
  }

  public getDeliveryTypeTranslate(orderType: ORDER_TYPE) {
    if (orderType in this.deliveryTypesTranslates) {
      return this.translateService.instant(this.deliveryTypesTranslates[orderType]);
    }
    return orderType;
  }

  public getOpenTypeTranslates(orderType: OPEN_TYPE) {
    if (orderType in this.openTypeTranslates) {
      return this.translateService.instant(this.openTypeTranslates[orderType]);
    }
    return orderType;
  }

}

export interface OfferModel {
  customEasyFee: number;
  removed?: string;
  deliveryTypes: { code: string, description: { en?: string, fr?: string } }[];
  description: string;
  estimatedTimePreparation: number;
  id: string;
  isActive: boolean;
  isAutoPlanning: boolean;
  isGlobalOpen: boolean;
  isOpen: boolean;
  merchant: UserModel;
  minOrder: number;
  name: string;
  OfferCategories: {
    assortment: string,
    id: string,
    description: { en?: string; fr?: string; }
    photoHighUrl: string;
    photoLowUrl: string;
    photoMediumUrl: string;
    position: number;
  }[];
  openType: string;
  pauseUntil?: string;
  openingTimes: any;
  orderPrefix: string;
  photoUrlFull: string;
  photoUrlLow: string;
  photoUrlMedium: string;
  priceRange: number;
  rating: number;
  reviewsCount: number;
  vacationEnd: string;
  vacationStart: string;
  timezone: string;
  whatAreWeSelling: string[];
}

