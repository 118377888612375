import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonOfferLinkComponent } from './common-offer-link/common-offer-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonFormModule } from '../common-form/common-form.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NbIconModule } from '@nebular/theme';


@NgModule({
  declarations: [
    CommonOfferLinkComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CommonFormModule,
    ClipboardModule,
    NbIconModule
  ],
  exports: [
    CommonOfferLinkComponent
  ]
})
export class CommonOfferLinkModule {
}
