import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-common-grid-toggle',
  templateUrl: './common-grid-toggle.component.html',
  styleUrls: ['./common-grid-toggle.component.scss']
})
export class CommonGridToggleComponent implements OnInit {
  public key: string;
  public isUsers = false;

  @Output() changeMethod: EventEmitter<any> = new EventEmitter();

  @Input('rowData') rowData: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  public changed(event) {
    this.changeMethod.emit({event, data: this.rowData});
  }

}
