export enum ORDER_TYPE {

  /**
   * Objednávka čo najskôr s donáškou
   */
  IMMEDIATE_DELIVERY = 'immediate_delivery',

  /**
   * Objednávka s osobným vyzdvihnutím
   */
  IMMEDIATE_PICKUP = 'immediate_pickup',

  /**
   * Objednávka na určitý čas s donáškou
   */
  ROUNDTRIP_DELIVERY = 'roundtrip_delivery',

  /**
   * Objednávka na určitý čas s osobným vyzdvihnutím
   */
  ROUNDTRIP_PICKUP = 'roundtrip_pickup'
}

export enum OPEN_TYPE {
  VACATION = 'VACATION',
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  PAUSE = 'PAUSE'
}

export enum OFFER_PAUSE {
  PAUSE_MINUTES = 'PAUSE_MINUTES',
  PAUSE_DATETIME = 'PAUSE_DATETIME',
  PAUSE_UNTIL_NEXT_OPENING_TIME = 'PAUSE_UNTIL_NEXT_OPENING_TIME'
}
