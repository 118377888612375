import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input, OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewContainerRef
} from '@angular/core';
import { AbstractControl, ControlContainer, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonControlErrorContainerDirective } from './common-control-error-container.directive';
import { CommonInputErrorComponent } from './common-input-error/common-input-error.component';

@Directive({
  selector: '[formControl], [formControlName], [formGroup], [formGroupName], [formArrayName], [ngModel]'
})
export class CommonControlErrorsDirective implements OnInit, OnDestroy {
  @Input() customErrors = {};
  defaultErrors = {
    required: (error) => this.translateService.instant('common.errors.default.required'),
    email: (error) => this.translateService.instant('common.errors.default.email'),
    minlength: ({requiredLength, actualLength}) =>
      `this.translateService.instant('common.errors.default.requiredLength'') ${requiredLength}
        this.translateService.instant('common.errors.default.actualLength') ${actualLength}`,
    pattern: (error) => this.translateService.instant('common.errors.default.pattern'),
    passwordMatch: (error) => this.translateService.instant('common.errors.default.passwordMustMatch'),
  };

  private ref: ComponentRef<CommonInputErrorComponent>;
  private anchor: ViewContainerRef;
  private control: AbstractControl;
  valueChangeSub$: Subscription;

  constructor(private translateService: TranslateService,
              private vcr: ViewContainerRef,
              private resolver: ComponentFactoryResolver,
              private host: ElementRef,
              @Optional() @Self() private ngControl: NgControl,
              @Optional() @Self() private controlContainer: ControlContainer,
              @Optional() private controlErrorContainer: CommonControlErrorContainerDirective) {
    this.anchor = controlErrorContainer ? controlErrorContainer.vcr : vcr;
  }

  ngOnInit(): void {
    this.control = (this.controlContainer || this.ngControl).control;
    this.valueChangeSub$ = this.control
      .valueChanges
      .subscribe(() => {
        const controlErrors = this.control.errors;
        if (controlErrors) {
          const [firstKey] = Object.keys(controlErrors);
          const getError = this.customErrors[firstKey] || this.defaultErrors[firstKey];

          if (!getError) {
            return;
          }
          const text = typeof getError === 'function' ? getError(controlErrors[firstKey]) : getError;
          this.setError(text);
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  setError(text: string): void {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(CommonInputErrorComponent);
      this.ref = this.vcr.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.destroy();
    }
    this.ref = null;
    this.valueChangeSub$.unsubscribe();
  }

}
