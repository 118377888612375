<div *ngIf="offerDeepLinkUrl">
  <label>
    {{'offerDetail.offerLink.label' | translate}}
  </label>
  <nb-form-field>
    <input fullWidth type="text" readonly nbInput [value]="offerDeepLinkUrl">
    <button status="primary" nbSuffix nbButton ghost [cdkCopyToClipboard]="offerDeepLinkUrl">
      <nb-icon icon="copy">
      </nb-icon>
    </button>
  </nb-form-field>
  <div class="pt-2">
    <nb-form-field>
      <textarea fullWidth readonly nbInput rows="4">{{offerDeepLinkTag}}</textarea>
      <button status="primary" nbSuffix nbButton ghost [cdkCopyToClipboard]="offerDeepLinkTag">
        <nb-icon icon="copy">
        </nb-icon>
      </button>
    </nb-form-field>
  </div>
</div>
