import { Component, Input, OnInit } from '@angular/core';
import { FilteringWrapper } from 'smart-table-backend-wrapper';
import { CommonOfferOptionGroupInjectedService } from '../../services/common-offer-option-group-injected-service';
import { TranslateService } from '@ngx-translate/core';
import { CommonGridToggleComponent } from '../../../common-grid-component/components/common-grid-toggle/common-grid-toggle.component';
import { NbDialogService } from '@nebular/theme';
import { CommonOfferOptionGroupDetailComponent } from '../common-offer-option-group-detail/common-offer-option-group-detail.component';
import { COMMON_GRID_ACTION_BUTTONS } from '../../../../config';

@Component({
  selector: 'lib-common-offer-option-groups-list',
  templateUrl: './common-offer-option-groups-list.component.html',
  styleUrls: ['./common-offer-option-groups-list.component.scss']
})
export class CommonOfferOptionGroupsListComponent extends FilteringWrapper implements OnInit {
  @Input() offerId: string;
  @Input() commonOfferOptionGroupInjectedService: CommonOfferOptionGroupInjectedService;

  constructor(private translate: TranslateService,
              private dialogService: NbDialogService) {
    super();
  }

  ngOnInit(): void {

    this.initGridCustomization();
  }

  loadData(): void {
    this.loading = true;
    this.commonOfferOptionGroupInjectedService.getOptionGroupsPaginated(this.offerId, this.filteringObj)
      .subscribe(res => {
        this.filteringObj.setPager(
          res.meta.currentPage,
          res.meta.pageCount,
          res.meta.perPage,
          res.meta.total
        );
        this.source.load(res.data).then(() => {
          this.loading = false;
        });
      }, err => {
        this.loading = false;
      });
  }

  setColumns(): void {
    this.settings = {
      pager: false,
      localStorageId: 'offer-option-groups',
      mode: 'external',
      ...COMMON_GRID_ACTION_BUTTONS,
      actions: {
        columnTitle: this.translate.instant('common.offer_option_group.grid.actions'),
        add: true,
        delete: true
      },
      columns: {
        id: {
          title: this.translate.instant('common.offer_option_group.grid.id'),
          type: 'text',
          filter: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        name: {
          title: this.translate.instant('common.offer_option_group.grid.name'),
          type: 'html',
          valuePrepareFunction: (cell, row) => {
            return `<b>${cell}</b>`;
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        visibleName: {
          title: this.translate.instant('common.offer_option_group.grid.visibleName'),
          type: 'text',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        type: {
          title: this.translate.instant('common.offer_option_group.grid.optionType'),
          type: 'text',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        minCount: {
          title: this.translate.instant('common.offer_option_group.grid.minCount'),
          type: 'number',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        maxCount: {
          title: this.translate.instant('common.offer_option_group.grid.maxCount'),
          type: 'number',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        items: {
          title: this.translate.instant('common.offer_option_group.grid.optionItems'),
          type: 'text',
          valuePrepareFunction: (cell, row) => {
            return [...cell.map(item => item.name)];
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        isAvailable: {
          title: this.translate.instant('common.offer_option_group.grid.availability'),
          type: 'custom',
          renderComponent: CommonGridToggleComponent,
          onComponentInitFunction: (instance) => {
            instance.key = 'isAvailable';
            instance.changeMethod.subscribe(event => {
              this.loading = true;
              this.commonOfferOptionGroupInjectedService.updateOptionGroupAvailability(event.data.id, !event.data.isAvailable)
                .subscribe((res) => {
                  console.log(res);
                  this.loading = false;
                }, () => this.loading = false);
            });
          },
          filter: {
            type: 'list',
            config: {
              selectText: this.translate.instant('common.grid.select'),
              list: [
                {value: true, title: this.translate.instant('adminGrid.select_items.true')},
                {value: false, title: this.translate.instant('adminGrid.select_items.false')},
              ]
            }
          },
        }
      }
    };
  }

  async handleModalAction(rowData?: any) {
    const dialogDataOnClose = await this.dialogService.open(CommonOfferOptionGroupDetailComponent, {
      context: {
        groupData: rowData?.data,
        offerId: this.offerId,
        commonOfferOptionGroupInjectedService: this.commonOfferOptionGroupInjectedService
      }
    }).onClose.toPromise();
    console.log(dialogDataOnClose);
    if (dialogDataOnClose) {
      this.refreshData();
    }
  }

  delete(rowData?: any) {
    if (rowData.data.id) {
      this.loading = true;
      this.subs.push(
        this.commonOfferOptionGroupInjectedService.deleteOptionGroup(rowData.data.id).subscribe(res => {
          this.refreshData();
        }, error => this.loading = false)
      );
    }
  }
}
