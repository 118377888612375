import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    NbAlertModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbFormFieldModule, NbIconModule,
    NbInputModule, NbPopoverModule,
    NbSelectModule,
    NbSpinnerModule,
    NbToggleModule, NbTooltipModule
} from '@nebular/theme';
import { CommonInputValidatorsModule } from '../common-input-validators/common-input-validators.module';
import { CommonFormFieldInfoComponent } from './components/common-form-field-info/common-form-field-info.component';
import { TranslateModule } from '@ngx-translate/core';

const exportedModules = [
  FormsModule,
  ReactiveFormsModule,
  NbButtonModule,
  NbInputModule,
  NbSpinnerModule,
  NbSelectModule,
  NbAutocompleteModule,
  NbFormFieldModule,
  NbToggleModule,
  CommonInputValidatorsModule
];

@NgModule({
  declarations: [
    CommonFormFieldInfoComponent
  ],
    imports: [
        CommonModule,
        ...exportedModules,
        NbIconModule,
        NbTooltipModule,
        TranslateModule,
        NbPopoverModule,
        NbAlertModule
    ],
  exports: [
    ...exportedModules,
    CommonFormFieldInfoComponent
  ]
})
export class CommonFormModule {
}
