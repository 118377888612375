import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonControlErrorsDirective } from './common-control-errors.directive';
import { CommonControlErrorContainerDirective } from './common-control-error-container.directive';
import { CommonInputErrorComponent } from './common-input-error/common-input-error.component';


@NgModule({
  declarations: [CommonControlErrorsDirective, CommonControlErrorContainerDirective, CommonInputErrorComponent],
  imports: [
    CommonModule,
  ], exports: [
    CommonInputErrorComponent,
    CommonControlErrorsDirective,
    CommonControlErrorContainerDirective,
  ]
})
export class CommonInputValidatorsModule {
}
