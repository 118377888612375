import { Injectable } from '@angular/core';
import { APP_SUPPORTED_LANGUAGES, MERCHANT_BACK_OFFICE_LANG } from '../config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translateService: TranslateService) {
  }

  setLanguage(lang) {
    this.translateService.use(lang);
    localStorage.setItem(MERCHANT_BACK_OFFICE_LANG, lang);
    location.reload();
  }
}
