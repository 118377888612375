import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fromNumberToDays'
})
export class FromNumberToDaysPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(numberOfDay: number): string {
    if (numberOfDay) {
      switch (numberOfDay) {
        case 1:
          return this.translateService.instant('common.planning.days.monday');
        case 2:
          return this.translateService.instant('common.planning.days.tuesday');
        case 3:
          return this.translateService.instant('common.planning.days.wednesday');
        case 4:
          return this.translateService.instant('common.planning.days.thursday');
        case 5:
          return this.translateService.instant('common.planning.days.friday');
        case 6:
          return this.translateService.instant('common.planning.days.saturday');
        case 7:
          return this.translateService.instant('common.planning.days.sunday');
        default:
          return null;

      }
    } else {
      return null;
    }
  }

}
