import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'lib-common-approval-dialog',
  templateUrl: './common-approval-dialog.component.html',
  styleUrls: ['./common-approval-dialog.component.scss']
})
export class CommonApprovalDialogComponent implements OnInit {

  public isSubmitting: boolean;
  private subs = [];
  @Input() title: string;
  @Input() text: string;
  @Input() yesText = 'yes';
  @Input() noText = 'no';
  @Input() revertSubmitButtonColors: boolean;


  constructor(private dialogRef: NbDialogRef<CommonApprovalDialogComponent>) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.isSubmitting = true;
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.submit();
    }
  }

}
