import { FilteringWrapper, SmartTableFilterInterface } from 'smart-table-backend-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';

export class CommonQueryParamsFromGrid extends FilteringWrapper {

  constructor(protected router: Router, protected activatedRouter: ActivatedRoute) {
    super();
  }

  initGridChanges() {
    // if have some query params, set filter to grid from query params
    if (this.activatedRouter.snapshot.queryParamMap.keys.length > 0) {
      // @ts-ignore
      this.setFilterAndSortToChatGrid(this.activatedRouter.snapshot.queryParamMap.params);
      // if have no query params but have in localstorage - set filters from localStorage
    } else {
      this.loadData();
    }
    this.filteringChangesSub();
  }

  /**
   * set filters to grid
   * sort grid if have some sort data info
   *
   * @param objectOfParams : object of parameters to filters or sorting
   */
  setFilterAndSortToChatGrid(objectOfParams: { [key: string]: string }) {
    // create copy of object to be able to work with sort and remove after for future filter purposes
    const copyObjectOfParams = {...objectOfParams};
    if (copyObjectOfParams.sort) {
      const splitSort = copyObjectOfParams.sort.split('.');
      const sortObject = {
        field: splitSort[0],
        direction: splitSort[1]
      };
      // set sort to grid
      this.source.setSort([sortObject]);
      this.filteringObj.setSortingItems([sortObject]);
      // remove sort key from object for setting filter purposes
      delete copyObjectOfParams.sort;
    }
    if (Object.keys(copyObjectOfParams).length > 0) {
      const queryParamsToFilter = this.createQueryParamsFromObject(copyObjectOfParams);
      this.source.setFilter(queryParamsToFilter);
      //    this.filteringObj.setFilteringItems(queryParamsToFilter);
    } else {
      this.loadData();
    }
  }

  /**
   * create right format from object of parameters  to set filter to grid
   * @param objectOfParams: object of parameters to filters;
   */
  createQueryParamsFromObject(objectOfParams: { [key: string]: string }): SmartTableFilterInterface[] {
    const queryParamToFilter: SmartTableFilterInterface[] = [];
    Object.keys(objectOfParams).forEach(
      param => {
        queryParamToFilter.push({field: param, search: objectOfParams[param]});
      }
    );
    return queryParamToFilter;
  }

  /**
   *  listening on grid filtering
   *  put filters into query params
   */
  filteringChangesSub() {
    this.subs.push(this.source.onChanged().pipe(distinctUntilChanged()).subscribe((change) => {
      if (change.action === 'filter') {
        const queryParams: { [key: string]: string } = {};
        change.filter.filters.forEach(singleFilter => {
          // if user change filter to "nothing" -> set filter to null because of router behavior (remove query params which is equal to null)
          queryParams[singleFilter.field] = singleFilter.search === '' ? null : singleFilter.search.toString();
        });
        // change query params on activated route
        this.changeQueryParams(this.activatedRouter, queryParams);
      } else if (change.action === 'sort') {
        // change query params on activated route with sort info
        this.changeQueryParams(this.activatedRouter, {sort: `${change.sort[0].field}.${change.sort[0].direction}`});
      }
      // TODO IMPLEMENT THIS FIX IN SMART WRAPER TABLE LIB
      if (change.action === 'refresh') {
        this.loading = false;
      }
    }));
  }

  loadData(): void {
  }

  setColumns(): void {
  }

  /**
   * return promise which resolve after change query parameters in actual route
   *
   * @param activatedRoute: activate route
   * @param queryParams: Object of query parameters
   */
  changeQueryParams(activatedRoute: ActivatedRoute, queryParams: {
    [key: string]: string;
  }) {
    this.router.navigate([], {
      relativeTo: activatedRoute,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }
}
