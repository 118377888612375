import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { InjectedAuthService } from '../models/injectedAuthServiceModel';

@Injectable()
export class CommonTokenInterceptor implements HttpInterceptor {

  constructor(private authService: InjectedAuthService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentUserToken = this.authService.getAuthToken();
    if (currentUserToken) {
      if (!req.url.includes('translation.googleapis.com/language/translate/v2')) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUserToken}`
          }
        });
      }
    }
    return next.handle(req);
  }
}
