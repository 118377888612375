import { Component, Input, OnInit } from '@angular/core';
import { CommonOfferOptionGroupInjectedService } from '../../services/common-offer-option-group-injected-service';
import { OptionItem } from '../../models/option-item';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonSubscriber } from '../../../common-shared/classes/common-subscriber';
import { NbDialogRef } from '@nebular/theme';
import { fromCentsToEuro, fromEuroToCents } from '../../../../config';
import { CommonPrice } from '../../../../models/common-price';

@Component({
  selector: 'lib-common-offer-option-item-detail',
  templateUrl: './common-offer-option-item-detail.component.html',
  styleUrls: ['./common-offer-option-item-detail.component.scss']
})
export class CommonOfferOptionItemDetailComponent extends CommonSubscriber implements OnInit {
  public optionGroupForm: FormGroup;
  public isSubmitting: boolean;
  @Input() offerId: string;
  @Input() commonOfferOptionGroupInjectedService: CommonOfferOptionGroupInjectedService;
  @Input() itemData: OptionItem;

  constructor(
    private fb: FormBuilder, private dialogRef: NbDialogRef<CommonOfferOptionItemDetailComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm(this.itemData);
  }

  private initForm(itemData?: OptionItem) {
    this.optionGroupForm = this.fb.group({
      name: ['', [Validators.required]],
      isAvailable: [true, [Validators.required]],
      price: [0, [Validators.required, Validators.min(0)]],
      vat: [0, [Validators.required, Validators.min(0)]]
    });
    if (itemData) {
      this.optionGroupForm.patchValue({
        ...itemData,
        price: fromCentsToEuro((itemData.price as CommonPrice).priceWithVat)
      });
    }
  }

  submit() {
    const data = this.optionGroupForm.getRawValue();
    data.price = fromEuroToCents(data.price);
    console.log(data);
    this.isSubmitting = true;
    if (this.itemData) {
      this.subs.push(this.commonOfferOptionGroupInjectedService.updateOptionItem(this.itemData.id, data).subscribe(res => {
          this.dialogRef.close(true);
        })
      );
    } else {
      this.subs.push(this.commonOfferOptionGroupInjectedService.createOptionItem(data, this.offerId).subscribe(res => {
          this.dialogRef.close(true);
        })
      );
    }
  }

}
