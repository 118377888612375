import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl, NgModel, Validators } from '@angular/forms';
import { InjectedOfferService } from '../../models/injectedOfferServiceModel';
import { NbDialogService } from '@nebular/theme';
import { CommonApprovalDialogComponent } from '../../../common-approval-dialog/components/common-approval-dialog/common-approval-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { InjectOfferCategoryService } from '../../models/injected-offer-category-service-model';


@Component({
  selector: 'lib-common-offer-categories',
  templateUrl: './common-offer-categories.component.html',
  styleUrls: ['./common-offer-categories.component.scss']
})
export class CommonOfferCategoriesComponent implements OnInit {

  public offerCategoriesIsLoading: boolean;
  public wasDragged = false;
  public isSavingOrder = false;
  public isCreatingNew = false;
  public categories: { id: string, name: string, position: number, isUpdating?: boolean, isDeleting: boolean }[] = [];
  public newCategoryFromControl = new FormControl('', Validators.required);
  @Input() id;
  @Input() injectedOfferService: InjectedOfferService;
  @Input() offerCategoryService: InjectOfferCategoryService;

  constructor(private dialogService: NbDialogService, private translateService: TranslateService, private router: Router) {
  }

  ngOnInit(): void {
    this.wasDragged = false;
    this.isSavingOrder = false;
    this.initOfferCategories();
  }

  /**
   * create new category
   */
  public addCategory() {
    this.isCreatingNew = true;
    this.offerCategoryService.createMenuCategory(this.id, {name: this.newCategoryFromControl.value})
      .subscribe((res: any[]) => {
        this.categories = res;
        this.isCreatingNew = false;
        this.newCategoryFromControl.setValue('');
      }, error => {
        this.isCreatingNew = false;
      });
  }

  /**
   * remove single category
   * @param categoryToDelete
   *
   */
  public removeCategory(categoryToDelete) {
    this.dialogService.open(CommonApprovalDialogComponent, {
      context: {
        text: this.translateService.instant('common.dialog.delete.text'),
        title: this.translateService.instant('common.dialog.delete.title'),
        yesText: this.translateService.instant('common.dialog.delete.yesText'),
        noText: this.translateService.instant('common.dialog.delete.noText')
      },
    }).onClose.subscribe(
      res => {
        if (res) {
          categoryToDelete.isDeleting = true;
          this.offerCategoryService.deleteMenuCategory(this.id, categoryToDelete.id)
            .subscribe((categories: any[]) => {
              this.categories = categories;
              categoryToDelete.isDeleting = false;
            }, error => {
              categoryToDelete.isDeleting = false;
            });
        }
      }
    );
  }

  /**
   *get and order categories
   * @private
   */
  private initOfferCategories() {
    if (this.id) {
      this.offerCategoriesIsLoading = true;
      this.offerCategoryService.getOfferCategories(this.id).subscribe((res) => {
          this.categories = res.sort((a, b) => {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            return 0;
          });
          this.offerCategoriesIsLoading = false;
        }, error => {
          this.router.navigate(['/', 'merchant', 'offers']);
          this.offerCategoriesIsLoading = false;
        }
      );
    }
  }

  /**
   * when drop item
   * @param event
   */
  drop(event: CdkDragDrop<any[]>) {
    this.wasDragged = true;
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }


  /**
   * update single category
   * @param category
   * @param nameInput
   */
  public updateCategory(category, nameInput: NgModel) {
    category.isUpdating = true;
    console.log(category);
    this.offerCategoryService.updateMenuCategory(this.id, category.id, {name: category.name})
      .subscribe((res: any[]) => {
        nameInput.reset(category.name);
        category.isUpdating = false;
      }, error => {
        category.isUpdating = false;
      });
  }

  /**
   * save order of categories
   */
  public saveOrder() {
    this.isSavingOrder = true;
    const orderedCategories = this.categories.map((category, index) => {
      return {
        id: category.id,
        position: index
      };
    });
    this.offerCategoryService.updateMenuCategoriesPosition(this.id, orderedCategories)
      .subscribe((res: any[]) => {
        this.isSavingOrder = false;
        this.wasDragged = false;
        this.categories = res;
      }, error => {
        this.isSavingOrder = false;
        this.wasDragged = false;
      });
  }

}
