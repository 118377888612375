import { Observable } from 'rxjs';

export abstract class InjectedAllergenService {
  abstract getAllergens(): Observable<Allergen[]>;
}

export interface Allergen {
  code: string;
  description: {
    en: { name: string, description: string };
    fr: { name: string, description: string };
  };
}
