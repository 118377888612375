import { Observable } from 'rxjs';

// TODO IMPLEMENT SHARED FUNCTIONS FUNCTIONALITY
export abstract class InjectedAuthService {
  abstract logOutUser();

  abstract getRefreshToken(): string;

  abstract refreshTokens(): Observable<any>;

  abstract getAuthToken(): string;

}
