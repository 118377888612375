import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InjectedOfferPlanningService, OpeningHoursModel} from '../../models/injectedOfferPlanningService';
import {CommonSubscriber} from '../../../common-shared/classes/common-subscriber';
import {InjectedOfferService} from '../../../common-offer/models/injectedOfferServiceModel';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as momentTimezone from 'moment-timezone';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'lib-common-offer-planning',
  templateUrl: './common-offer-planning.component.html',
  styleUrls: ['./common-offer-planning.component.scss']
})
export class CommonOfferPlanningComponent extends CommonSubscriber implements OnInit {

  typeOfPlanningIsLoading: boolean;
  isManualOpeningOpenLoading: boolean;
  isManualOpeningOpen: boolean;
  submitOpenHoursIsLoading: boolean;
  timezone: string;
  offset: string;
  wholeWeekOpenHoursFormArray: FormArray;
  typeOfPlanning: FormControl = new FormControl();
  customErrors = {
    oneFieldMissing: this.translateService.instant('common.planning.customErrors.oneFieldMissing'),
    startTimeIsAfterEnd: this.translateService.instant('common.planning.customErrors.startTimeIsAfterEnd')
  };
  typeOfPlanningArray = [{name: 'Automatic', value: true}, {name: 'Manual', value: false}];
  @Input() injectedOfferPlanningService: InjectedOfferPlanningService;
  @Input() injectedOfferService: InjectedOfferService;
  @Input() offerId: string;
  @Output() submitOpeningHoursEmitter = new EventEmitter<OpeningHoursModel[]>();

  constructor(private fb: FormBuilder,
              private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.initWholeWeekOpenHoursFormArray();
    this.getOfferData(this.offerId);
  }

  initWholeWeekOpenHoursFormArray() {
    this.wholeWeekOpenHoursFormArray = new FormArray([]);
    for (let i = 1; i < 8; i++) {
      this.wholeWeekOpenHoursFormArray.push(this.fb.group({
        day: [i, Validators.required],
        isAvailable: [false, Validators.required],
        times: this.fb.array([this.fb.group({
          start: [null],
          end: [null],
        }, {validators: [this.comparisonValidator(), this.endTimeAfterStartTimeValidator()]}), this.fb.group({
          start: [null],
          end: [null],
        }, {validators: [this.comparisonValidator(), this.endTimeAfterStartTimeValidator()]})])
      }));
    }
    console.log(this.wholeWeekOpenHoursFormArray);
  }


  changeManualPlanningIsOpen(value: boolean) {
    this.isManualOpeningOpenLoading = true;
    this.subs.push(this.injectedOfferPlanningService.updateManualOpen(this.offerId, value).subscribe(
      res => {
        this.isManualOpeningOpen = res.isOpen;
        this.isManualOpeningOpenLoading = false;
      }, () => {
        this.isManualOpeningOpen = !value;
        this.isManualOpeningOpenLoading = false;
      }
    ));
  }


  public getOfferData(offerId) {
    this.typeOfPlanningIsLoading = true;
    this.subs.push(this.injectedOfferService.getSingleOffer(offerId).subscribe(
      res => {
        console.log(res);
        this.timezone = res.timezone;
        this.offset = momentTimezone().tz(res.timezone).format('Z');
        this.typeOfPlanning.setValue(res.isAutoPlanning);
        this.isManualOpeningOpen = res.isOpen;
        if (res.openingTimes.length > 0) {
          res.openingTimes.forEach(singleDay => {
            this.wholeWeekOpenHoursFormArray.controls.some((singleForm, formIndex) => {
              if (singleDay.dayOfWeek === singleForm['controls'].day.value) {
                singleForm['controls'].isAvailable.setValue(singleDay.isAvailable);
                if (singleDay.times && singleDay.times.length > 0) {
                  singleDay.times.forEach((timeSlot, indexOfTimeSlot) => {
                    singleForm['controls'].times.controls[indexOfTimeSlot].controls.start.setValue(momentTimezone(timeSlot.startTime).tz(this.timezone));
                    singleForm['controls'].times.controls[indexOfTimeSlot].controls.end.setValue(momentTimezone(timeSlot.endTime).tz(this.timezone));
                  });
                }
                this.wholeWeekOpenHoursFormArray.controls[formIndex].patchValue(singleDay);
                return true;
              }
            });
          });
        }
        this.typeOfPlanningIsLoading = false;
      }, () => {
        this.typeOfPlanningIsLoading = false;
      }
    ));
  }

  submitOpeningHours() {
    const dataToEmit = this.wholeWeekOpenHoursFormArray.getRawValue() as OpeningHoursModel[];
    this.submitOpeningHoursEmitter.emit(dataToEmit);
  }


  changeTypeOfPlanning(value: boolean) {
    this.typeOfPlanningIsLoading = true;
    this.subs.push(this.injectedOfferPlanningService.updateAutoPlanning(this.offerId, value).subscribe(
      res => {
        this.typeOfPlanning.setValue(res.isAutoPlanning);
        this.typeOfPlanningIsLoading = false;
      }, () => {
        this.typeOfPlanning.setValue(!value);
        this.typeOfPlanningIsLoading = false;
      }
    ));
  }


  public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.get('start');
      const control2 = group.get('end');
      // console.log(control1.value);
      // console.log(control2.value);
      if ((control1.value && !control2.value) || (!control1.value && control2.value)) {
        return {oneFieldMissing: true};
      } else {
        return null;
      }
    };
  }

  public endTimeAfterStartTimeValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.get('start');
      const control2 = group.get('end');
      // console.log(control1.value);
      // console.log(control2.value);
      if (control1.value && control2.value) {
        if (momentTimezone(control1.value).tz(this.timezone).isSameOrAfter(momentTimezone(control2.value).tz(this.timezone))) {
          return {startTimeIsAfterEnd: true};
        } else {
          return null;
        }
      }
    };
  }

  public resetOpeningTimes(hoursFormIndex: number, dayIndex: number, nbTimepickerStart: HTMLInputElement, nbTimepickerEnd: HTMLInputElement) {
    nbTimepickerStart.value = null;
    nbTimepickerEnd.value = null;
    this.wholeWeekOpenHoursFormArray.controls[hoursFormIndex]['controls'].times.controls[dayIndex].controls.start.setValue(null);
    this.wholeWeekOpenHoursFormArray.controls[hoursFormIndex]['controls'].times.controls[dayIndex].controls.end.setValue(null);
  }
}
