import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-common-grid-image',
  templateUrl: './common-grid-image.component.html',
  styleUrls: ['./common-grid-image.component.scss']
})
export class CommonGridImageComponent implements OnInit {
  public key: string;

  @Input('rowData') rowData: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
