import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CommonToastHandlerService {

  constructor(private toastrService: ToastrService,
              private translateService: TranslateService) {
  }

  /**
   * Show main response error and all validation errors
   *
   * @param error http error - used to parse toast titles
   * @param message toast additional message
   */
  public showErrorToastsResponse(error: HttpErrorResponse, message?: string): void {
    if (error && error.error) {
      this.toastrService.error(
        message,
        error.error.code ? this.translateService.instant('be.errors.' + error.error.code) : error.error.message,
      );
    } else {
      this.toastrService.error(
        message,
        this.translateService.instant('toasts.error.title'),
      );
    }

    if (error && error.error && error.error.errors) {
      const errorsObject = error.error.errors;
      Object.keys(errorsObject).forEach(key => {
        this.toastrService.error(
          message,
          `${key} + ${errorsObject[key]}`,
        );
      });
    }
  }


  /**
   * Show success response backend message
   *
   * @param title toast title
   * @param message toast additional message
   */
  public showSuccessToastsResponse(title: any, message?: string): void {
    if (title) {
      this.toastrService.success(
        message,
        title,
      );
    } else {
      this.toastrService.success(
        message,
        this.translateService.instant('toasts.success.title')
      );
    }
  }
}
