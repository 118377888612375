<nb-card>
  <nb-card-header>
    <div class="row">
      <div *ngIf="hasBackButton || hasRightCornerAdditional" class="col-6 order-0 col-sm-3 order-0">
        <button [libCommonBackButtonHistory]="backRouteArray" nbButton ghost type="button">
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>
      <div class="col order-2 order-sm-1">
        <div class="text-center">
    <span>
       {{title}}
    </span>
          <span *ngIf="titleAdditionalDetail">
          {{titleAdditionalDetail}}
    </span>
        </div>
      </div>
      <div *ngIf="hasBackButton || hasRightCornerAdditional"
           class="col-6 col-sm-3 d-flex justify-content-end order-1 order-sm-2">
        <ng-content select="[additional]">
        </ng-content>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <ng-content>
    </ng-content>
  </nb-card-body>
</nb-card>
