<nb-alert>
  <div class="text-center text-uppercase">
    {{'common.offer.planning.title' | translate}}
  </div>
</nb-alert>
<div class="row d-flex justify-content-center mb-3">
  <div class="col-auto">
    <nb-select-label>
      {{'common.planning.selectTypeOfPlanning' | translate}}
    </nb-select-label>
    <nb-select class="d-block" [nbSpinner]="typeOfPlanningIsLoading" (selectedChange)="changeTypeOfPlanning($event)"
               [formControl]="typeOfPlanning">
      <nb-option [value]="singleType.value" *ngFor="let singleType of typeOfPlanningArray">
        {{singleType.name}}
      </nb-option>
    </nb-select>
  </div>
</div>
<div class="row">
  <div [class.disabled-container]="!typeOfPlanning.value" class="col-6">
    <nb-alert class="position-relative font-weight-normal"
              [accent]="typeOfPlanning.value ? 'success' : 'basic'">
      <div class="text-center font-weight-bold">
        {{'common.offer.planning.automaticPlanning' | translate}}
      </div>
    </nb-alert>
    <div class="d-flex flex-column align-items-center">
      <div class="text-center p-1"><b>
        {{'common.planning.timezone' | translate}}
      </b></div>
      <div class="text-center pb-3">
        {{timezone}} (UTC {{offset}})
      </div>
      <table class="w-100">
        <tr>
          <th>
            {{'common.planning.day' | translate}}
          </th>
          <th class="text-center">
            {{'common.planning.openingHours' | translate}}
          </th>
          <th>
            {{'common.planning.isAvailable' | translate}}
          </th>
        </tr>
        <ng-container
          *ngFor="let oneDayOpenHoursForm of wholeWeekOpenHoursFormArray.controls; let hoursFormIndex = index">
          <ng-container [formGroup]="oneDayOpenHoursForm">
            <tr>
              <td>
                {{oneDayOpenHoursForm['controls'].day.value | fromNumberToDays}}
              </td>
              <td class="p-2">
                <ng-container
                  *ngFor="let oneTimeSlot of oneDayOpenHoursForm['controls'].times.controls; let dayIndex = index">
                  <div class="d-flex align-items-center" [customErrors]="customErrors" [formGroup]="oneTimeSlot">
                    <div class="mr-3">
                      <!--                      {{oneTimeSlot.controls['start'].value}}-->
                      <input #nbTimepickerStart
                             class="mr-2" fullWidth nbInput readonly="readonly" formControlName="start" [nbTimepicker]="timepickerStart">
                      <nb-timepicker #timepickerStart></nb-timepicker>
                    </div>
                    <div class="mr-3 ml-3">
                      To
                    </div>
                    <div class="ml-3">
                      <!--                      {{oneTimeSlot.controls['end'].value}}-->
                      <input #nbTimepickerEnd
                             class="mr-2" fullWidth nbInput readonly="readonly" formControlName="end" [nbTimepicker]="timepickerEnd">
                      <nb-timepicker #timepickerEnd></nb-timepicker>
                    </div>
                    <div class="p-2" (click)="resetOpeningTimes(hoursFormIndex, dayIndex, nbTimepickerStart, nbTimepickerEnd)">
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                </ng-container>
              </td>
              <td class="text-center">
                <nb-toggle [(checked)]="oneDayOpenHoursForm['controls'].isAvailable.value"></nb-toggle>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
      <button (click)="submitOpeningHours()" nbButton
              [disabled]="wholeWeekOpenHoursFormArray.invalid"
              [nbSpinner]="submitOpenHoursIsLoading"
              status="primary"> {{'commonLib.button.submit' | translate}}</button>
    </div>
  </div>
  <div [class.disabled-container]="typeOfPlanning.value" [nbSpinner]="isManualOpeningOpenLoading"
       class="col-6 text-center font-weight-bold">
    <nb-alert class="position-relative font-weight-normal"
              [accent]="!typeOfPlanning.value ? 'success' : 'basic'">
      <div class="text-center font-weight-bold">
        {{'common.offer.planning.manualPlanning' | translate}}
      </div>
    </nb-alert>
    <div class="text-center mt-3">
      <div class="mb-1">
        {{'common.offer.planning.isOfferOpen' | translate}}
      </div>
      <nb-toggle [checked]="isManualOpeningOpen" (checkedChange)="changeManualPlanningIsOpen($event)"
                 status="primary">
      </nb-toggle>
    </div>
  </div>
</div>
