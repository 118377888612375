import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NbIconModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbThemeModule,
  NbTimepickerModule
} from '@nebular/theme';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonTokenInterceptor,
  CommonRefreshTokenInterceptor,
  CommonToastHandlerService,
  CommonToastHandlerInterceptor, TRANSLATE_CATEGORIES, appInitializerFactory
} from 'common-lib';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CommonBackbuttonHistoryModule } from 'common-lib';
import { NbMomentDateModule } from '@nebular/moment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../../../src/environments/environment';
import { BACK_OFFICE_LOCALSTORAGE_LANGUAGE_KEY } from '../../../../src/config';
import { MERCHANT_BACK_OFFICE_LANG } from './config';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.url}v2/free/translation/`, `?categories=${TRANSLATE_CATEGORIES.BO_MERCHANT},${TRANSLATE_CATEGORIES.CATEGORY_SHARED_WEB},${TRANSLATE_CATEGORIES.ERRORS}`);
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NbThemeModule.forRoot({name: 'easyMerchant'}),
    NbMenuModule.forRoot(),
    ToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbIconModule,
    NbTimepickerModule.forRoot(),
    NbMomentDateModule,
    CommonBackbuttonHistoryModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [{
    provide: APP_INITIALIZER,
    deps: [TranslateService],
    useFactory: (dep1) => {
      return appInitializerFactory(
        dep1,
        MERCHANT_BACK_OFFICE_LANG,
        environment);
    },
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: CommonRefreshTokenInterceptor,
    deps: [AuthService, Router],
    multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonTokenInterceptor,
      deps: [AuthService],
      multi: true
    },
    {
      provide: CommonToastHandlerService,
      deps: [ToastrService, TranslateService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonToastHandlerInterceptor,
      deps: [CommonToastHandlerService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
