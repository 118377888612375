import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpEvent
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, mergeMap, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InjectedAuthService } from '../models/injectedAuthServiceModel';


@Injectable()
export class CommonRefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authService: InjectedAuthService,
              private router: Router) {
  }

  /*interceptOld(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError(err => {
      /!*const error = err.error.message || err.statusText;*!/
      if (err.status >= 400 && (err.status !== 401)) {
        return throwError(err);
      }

      if (request.url.indexOf('/auth/refresh') > 0 && err.status === 401) {
        this.authService.logOutUser();
        return throwError(err);
      }

      if (err.status === 401 && !this.router.url.includes('auth/login')) {
        if (!this.authService.getRefreshToken()) {
          return next.handle(request);
        } else {
          return this.authService.refreshTokens().pipe(
            take(1),
            mergeMap(() => {
              return next.handle(request);
            })
          );
        }
      }
      return throwError(err);
    }));
  }*/

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      /*const error = err.error.message || err.statusText;*/
      if (err.status >= 400 && (err.status !== 401)) {
        return throwError(err);
      }
      if (request.url.indexOf('/auth/refresh') > 0 && err.status === 401) {
        this.authService.logOutUser();
        return throwError(err);
      }
// TODO CHECK STATUS CODE TO LOGOUT
      console.log(' REFRESH TOKEN INTERCEPTOR');
      // 1004 - backend error code - ExpiredAccessTokenException
      if (err.status === 401 && err.error.code === 1004) {
        if (!this.authService.getRefreshToken()) {
          this.authService.logOutUser();
          return throwError(err);
        } else {
          return this.handleRefreshTokenUnauthorized(request, next);
        }
      }
      return throwError(err);
    }));
  }

  handleRefreshTokenUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      // get a new token via userService.refreshToken
      return this.authService.refreshTokens().pipe(
        /*tap(res => {

          // this.store.dispatch(new SetUser(res.user));
        }),*/
        switchMap((newToken: string) => {
          // did we get a new token retry previous request
          if (newToken) {
            this.tokenSubject.next(newToken);
            // return next.handle(this.addToken(req, newToken));
            return next.handle(req);
          }
          this.authService.logOutUser();
          return throwError(new Error('logout'));
        })
        , catchError(error => {
          console.log(' ERROR from first request catched ');
          // this.authService.logOutUser();
          return throwError(error);
        })
        , finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null)
        , take(1)
        , switchMap(token => {
          // return next.handle(this.addToken(req, token));
          return next.handle(req);
        })
      );
    }
  }
}
