import { Component, Input, OnInit } from '@angular/core';
import { OfferModel } from '../../common-offer/models/injectedOfferServiceModel';

@Component({
  selector: 'lib-common-offer-link',
  templateUrl: './common-offer-link.component.html',
  styleUrls: ['./common-offer-link.component.scss']
})
export class CommonOfferLinkComponent implements OnInit {
  public offerDeepLinkUrl = '';
  public offerDeepLinkTag = '';
  public offer: OfferModel | any;

  @Input() backendUrl: string;

  @Input('offer')
  // TODO AFTER ADMIN OFFER EDIT UPDATE CHANGE TYPE
  set offerData(offer: OfferModel | any) {
    this.offer = offer;
    this.setOfferDeeplinkUrl(this.offer);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  setOfferDeeplinkUrl(offer: OfferModel | any) {
    console.log(this.backendUrl);
    if (offer) {
      this.offerDeepLinkUrl = `${this.backendUrl}download-redirect?deepLink=easy-customer://offer?offerId=${offer.id}`;
      this.offerDeepLinkTag = `<a href="${this.offerDeepLinkUrl}" title="${offer.name}">${offer.name}</a>`;
      // window.location.href = this.offerDeepLinkUrl;s
    } else {
      this.offerDeepLinkUrl = '';
      this.offerDeepLinkTag = ``;
    }
  }
}
