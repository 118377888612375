import { Component, Input, OnInit } from '@angular/core';
import { NbTrigger } from '@nebular/theme';

@Component({
  selector: 'lib-common-form-field-info',
  templateUrl: './common-form-field-info.component.html',
  styleUrls: ['./common-form-field-info.component.scss']
})
export class CommonFormFieldInfoComponent implements OnInit {
  popoverTrigger = NbTrigger.HOVER;
  @Input() text: string;
  @Input() showInTooltip: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
