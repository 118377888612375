import { BackendFilteringObject } from 'smart-table-backend-wrapper';
import { Observable } from 'rxjs';

export abstract class InjectedOfferProductService {
  abstract getOfferProducts(id: string, filteringObject: BackendFilteringObject): Observable<{ meta: any, data: OfferProductModel[] }>;

  abstract updateProductAvailability(id: string, menuProductId: string, isAvailable: boolean);

  abstract getSingleOfferProduct(offerId, productId): Observable<OfferProductModel>;

  abstract deleteSingleProduct(offerId, productId): Observable<any>;
}

export interface OfferProductModel {
  allergens: any[];
  category: { id: string, name: string, position: number };
  currency: {};
  description: string;
  id: string;
  ingredients: string;
  isAvailable: true;
  menuOptions: [];
  name: string;
  photoUrlFull: string;
  photoUrlLow: string;
  photoUrlMedium: string;
  position: number;
  price: {};
  vat: number;

}

export interface ProductFormModel {
  allergens: string[];
  description: string;
  ingredients: string;
  isAvailable: boolean;
  menuCategoryId: string;
  menuOptionGroup: string[];
  name: string;
  photo: string;
  price: number;
  vat: number;
}
