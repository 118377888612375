<nb-card>
  <nb-card-header>
    <div *ngIf="itemData; else createNewTitle">
      {{ 'common.offer_option_item.detail.updateExisting' | translate }}: {{itemData.name}}
    </div>
    <ng-template #createNewTitle>
      {{ 'common.offer_option_item.detail.createNew' | translate }}
    </ng-template>
  </nb-card-header>
  <nb-card-body>
    <div class="row" [formGroup]="optionGroupForm">
      <div class="col-12">
        <div class="form-group">
          <label for="name" class="required">{{ 'common.offer_option_item.detail.name' | translate }}</label>
          <input type="text"
                 formControlName="name"
                 nbInput
                 id="name"
                 fullWidth
                 [placeholder]="'common.offer_option_item.detail.name' | translate">
        </div>
        <div class="form-group">
          <nb-toggle formControlName="isAvailable">{{ 'common.offer_option_item.detail.isAvailable' | translate }}</nb-toggle>
        </div>
        <div class="form-group">
          <label for="price" class="required">{{ 'common.offer_option_item.detail.priceWithVat' | translate }}</label>
          <nb-form-field>
            <nb-icon nbSuffix icon="euro-sign"></nb-icon>
            <input type="number"
                   formControlName="price"
                   nbInput
                   id="price"
                   fullWidth
                   [placeholder]="'common.offer_option_item.detail.priceWithVat_label' | translate">
          </nb-form-field>
        </div>
        <div class="form-group">
          <label for="vat" class="required">{{ 'common.offer_option_item.detail.vat' | translate }}</label>
          <nb-form-field>
            <nb-icon nbSuffix icon="percent"></nb-icon>
            <input type="number"
                   formControlName="vat"
                   nbInput
                   id="vat"
                   fullWidth
                   [placeholder]="'common.offer_option_item.detail.vat_label' | translate">
          </nb-form-field>
        </div>
      </div>
      <div class="col-12 text-right">
        <button nbButton
                type="submit" status="primary"
                [disabled]="optionGroupForm.invalid"
                [nbSpinner]="isSubmitting"
                (click)="submit()">
          {{'common.offer_option_item.detail.submit' | translate}}
        </button>
      </div>
    </div>


  </nb-card-body>
</nb-card>
