<lib-common-form-field-info
  [text]="'common.offer.product.desc_info'| translate">
</lib-common-form-field-info>
<stbw-grid [filteringObject]="filteringObj"
           [settings]="settings"
           [source]="source"
           [isLoading]="loading"
           (edit)="edit($event)"
           (create)="addNewProduct($event)"
           (paginatorChangeEvent)="pageChanged($event)"
>
</stbw-grid>
