<stbw-grid
  [filteringObject]="filteringObj"
  [settings]="settings"
  [source]="source"
  [isLoading]="loading"
  (edit)="edit($event)"
  (paginatorChangeEvent)="pageChanged($event)"
>
</stbw-grid>



