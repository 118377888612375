<div [nbSpinner]="isLoading" nbSpinnerStatus="primary" [ngSwitch]="offer?.openType">
  <ng-container *ngSwitchCase="OPEN_TYPE.AUTO">
    <nb-card [style.min-width]="'300px'">
      <nb-card-header class="text-center">
        {{ 'common.offer.pauseDialog.header.automaticOpen' | translate }}
      </nb-card-header>
      <nb-card-body class="text-center">
        <form [formGroup]="pauseForm">
          <nb-select id="type" fullWidth formControlName="type" (selectedChange)="updatePauseValidators($event)" class="w-100 mb-3">
            <nb-option *ngFor="let select of pauseTypesForSelect" [value]="select.value">
              {{select.name | translate}}
            </nb-option>
          </nb-select>
          <div [ngSwitch]="pauseForm.controls.type.value">
            <div *ngSwitchCase="OFFER_PAUSE.PAUSE_MINUTES" class="w-100">
              <input id="minutes"
                     nbInput
                     fullWidth
                     type="text"
                     size="small"
                     readonly
                     formControlName="minutes"
                     [nbTimepicker]="timepicker">
              <nb-timepicker #timepicker singleColumn [step]="10"></nb-timepicker>
              <div *ngIf="pauseForm.controls.minutes.errors?.invalidMinPauseTime" class="text-danger">
                {{'common.offer.pauseDialog.error.minutes.minimal' | translate}}
              </div>
              <div *ngIf="pauseForm.controls.minutes.errors?.required" class="text-danger">
                {{'common.offer.pauseDialog.error.minutes.required' | translate}}
              </div>
            </div>
            <div *ngSwitchCase="OFFER_PAUSE.PAUSE_DATETIME" class="w-100">
              <input id="datetime"
                     nbInput
                     fullWidth
                     type="text"
                     size="small"
                     readonly
                     formControlName="datetime"
                     [nbDatepicker]="datetime">
              <nb-date-timepicker #datetime [min]="now" [format]="'DD.MM.yyyy HH:mm'"></nb-date-timepicker>
              <div *ngIf="pauseForm.controls.datetime.errors?.invalidMinPauseDateTime" class="text-danger">
                {{'common.offer.pauseDialog.error.datetime.minimal' | translate}}
              </div>
              <div *ngIf="pauseForm.controls.datetime.errors?.required" class="text-danger">
                {{'common.offer.pauseDialog.error.datetime.required' | translate}}
              </div>
            </div>
            <div *ngSwitchCase="OFFER_PAUSE.PAUSE_UNTIL_NEXT_OPENING_TIME" class="w-100">
              <div class="text-center mb-2">
                {{ 'common.offer.pauseDialog.header.nextOpeningTime' | translate }}
              </div>
              <div class="text-center font-weight-bold">
                {{offerNextOpen | date: 'short'}}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button nbButton
                    type="button"
                    size="tiny"
                    ghost
                    [nbSpinner]="isSubmitting"
                    status="primary"
                    [disabled]="pauseForm.invalid"
                    (click)="submitPauseForm()">
              <nb-icon class="mr-1" icon="save"></nb-icon>
              {{'common.offer.pauseDialog.submit' | translate}}
            </button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </ng-container>
  <ng-container *ngSwitchCase="OPEN_TYPE.PAUSE">
    <nb-card [style.min-width]="'300px'">
      <nb-card-header class="text-center">
        {{ 'common.offer.pauseDialog.header.pause' | translate }}
      </nb-card-header>
      <nb-card-body class="text-center">
        <div class="w-100">
          <div class="mb-2">
            {{ 'common.offer.pauseDialog.pauseUntilDate' | translate }}
          </div>
          <div class="font-weight-bold">
            {{offer.pauseUntil | date: 'short'}}
          </div>
          <div class="mt-3">
            <button nbButton
                    type="button"
                    size="tiny"
                    ghost
                    [nbSpinner]="isSubmitting"
                    status="primary"
                    (click)="deletePause()">
              <nb-icon class="mr-1" icon="trash"></nb-icon>
              {{'common.offer.pauseDialog.deletePause' | translate}}
            </button>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </ng-container>
</div>

