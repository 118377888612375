import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';


const routes: Routes = [{
  path: '',
  redirectTo: 'auth',
  pathMatch: 'full'
}, {
  path: 'auth',
  loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
}, {
  path: 'merchant',
  canLoad: [AuthGuard],
  canActivate: [AuthGuard],
  loadChildren: () => import('./modules/merchant/merchant.module').then(m => m.MerchantModule)
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
