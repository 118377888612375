import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OPEN_TYPE } from '../../../common-shared/models/order-type';
import { OfferModel } from '../../../common-offer/models/injectedOfferServiceModel';

@Component({
  selector: 'lib-common-grid-offer-open-info',
  templateUrl: './common-grid-offer-open-info.component.html',
  styleUrls: ['./common-grid-offer-open-info.component.scss']
})
export class CommonGridOfferOpenInfoComponent implements OnInit {
  public OPEN_TYPE = OPEN_TYPE;

  @Input() value: Partial<OfferModel>;
  @Output() onPauseClick = new EventEmitter();
  @Output() onManualClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  public pauseClicked() {
    this.onPauseClick.emit({data: this.value});
  }

  public toggleChanged(event) {
    this.onManualClick.emit({event, data: this.value});
  }

}
