import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonQueryParamsFromGrid } from '../../../common-shared/classes/common-query-params-from-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MERCHANT_ASSORTMENT } from '../../../common-shared/models/common-user';
import { HttpClient } from '@angular/common/http';
import { ORDER_TYPE } from '../../../common-shared/models/order-type';
import { InjectedOfferService } from '../../models/injectedOfferServiceModel';
import { NbDialogService } from '@nebular/theme';
import {
  CommonOfferPauseDialogComponent
} from '../../../common-offer-planning/components/common-offer-pause-dialog/common-offer-pause-dialog.component';
import {
  CommonGridOfferOpenInfoComponent
} from '../../../common-grid-component/components/common-grid-offer-open-info/common-grid-offer-open-info.component';
import { InjectedOfferPlanningService } from '../../../common-offer-planning/models/injectedOfferPlanningService';


@Component({
  selector: 'lib-common-offer-list',
  templateUrl: './common-offer-list.component.html',
  styleUrls: ['./common-offer-list.component.scss']
})
export class CommonOfferListComponent extends CommonQueryParamsFromGrid implements OnInit {

  @Input() environment;
  @Input() offerService: InjectedOfferService;
  @Input() offerPlanningService: InjectedOfferPlanningService;
  @Output() editAction = new EventEmitter();

  constructor(protected router: Router,
              private http: HttpClient,
              protected activatedRouter: ActivatedRoute,
              private translate: TranslateService,
              private dialog: NbDialogService) {
    super(router, activatedRouter);
  }

  ngOnInit(): void {
    // TODO: uncomment when BE finished

    /*   this.subs.push(this.http.get(`${this.environment.url}/admin/enum/site`).subscribe(
         (res: []) => {
           const listOfSites = [];
           res.forEach((singleRes: { code }) => {
             listOfSites.push({value: singleRes.code, title: singleRes.code});
           });
           this.settings.columns[site].filter.config.list = listOfSites;
           this.settings = Object.assign({}, this.settings);
         }
       ));*/
    this.turnOffAutoLoading();
    this.initGridCustomization();
    this.initGridChanges();
  }

  loadData(): void {
    this.loading = true;
    this.subs.push(
      this.offerService.getOffers(this.filteringObj).subscribe(res => {
        this.filteringObj.setPager(
          res.meta.currentPage,
          res.meta.pageCount,
          res.meta.perPage,
          res.meta.total
        );
        res.data.forEach(item => {
          item.assortment = item.merchant.assortment;
          item.merchantName = item.merchant.name;
          item.merchantSurname = item.merchant.merchantSurname;
          item.site = item.merchant.site.code;
          item.deliveryType = item.deliveryTypes;
        });
        this.source.load(res.data).then(() => {
          this.loading = false;
        });
      }, err => {
        this.loading = false;
      })
    );
  }

  setColumns(): void {
    this.settings = {
      pager: false,
      localStorageId: 'offers-list-grid',
      mode: 'external',
      edit: {
        editButtonContent: '<i class="far fa-edit"></i>',
        saveButtonContent: '<i class="fa fa-check"></i>',
        cancelButtonContent: '<i class="fas fa-times"></i>',
        confirmSave: true
      },
      actions: {
        columnTitle: this.translate.instant('common.offer.editOffer'),
        add: false,
        delete: false,
      },
      columns: {
        id: {
          title: this.translate.instant('common.offer.id'),
          type: 'text',
          filter: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        assortment: {
          title: this.translate.instant('common.offer.assortment'),
          type: 'text',
          filter: {
            type: 'list',
            config: {
              selectText: this.translate.instant('common.grid.select'),
              list: [
                {
                  value: MERCHANT_ASSORTMENT.SUPERMARKET,
                  title: this.offerService.getAssortmentTranslate(MERCHANT_ASSORTMENT.SUPERMARKET)
                },
                {
                  value: MERCHANT_ASSORTMENT.RESTAURANT,
                  title: this.offerService.getAssortmentTranslate(MERCHANT_ASSORTMENT.RESTAURANT)
                },
              ]
            }
          },
          valuePrepareFunction: (cell, row) => {
            return this.offerService.getAssortmentTranslate(cell);
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        openType: {
          title: this.translate.instant('common.offer.openClose'),
          type: 'custom',
          filter: false,
          sort: false,
          valuePrepareFunction: (cell, row) => {
            return row;
          },
          renderComponent: CommonGridOfferOpenInfoComponent,
          onComponentInitFunction: (instance) => {
            this.subs.push(instance.onPauseClick.subscribe((res: any) => {
              this.subs.push(this.dialog.open(CommonOfferPauseDialogComponent, {
                  context: {
                    offerId: res.data.id,
                    injectedOfferPlanningService: this.offerPlanningService,
                    injectedOfferService: this.offerService
                  }
                }).onClose.subscribe(() => this.refreshData())
              );
            }));
            this.subs.push(instance.onManualClick.subscribe((res: any) => {
              this.subs.push(this.offerPlanningService.updateManualOpen(res.data.id, res.event)
                .subscribe(
                  offer => {
                    this.source.update(instance.rowData, offer).finally(() => this.loading = false);
                  })
              );
            }));
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        name: {
          title: this.translate.instant('common.offer.name'),
          type: 'text',
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        offerCategories: {
          title: this.translate.instant('common.offer.offerCategories'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return cell.map(item => item.description[this.translate.currentLang]
              ? item.description[this.translate.currentLang].name
              : item.description.en?.name);
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        deliveryType: {
          title: this.translate.instant('common.offer.deliveryTypes'),
          type: 'text',
          filter: {
            type: 'list',
            config: {
              multiple: true,
              selectText: this.translate.instant('common.grid.select'),
              list: [
                {value: ORDER_TYPE.IMMEDIATE_DELIVERY, title: this.offerService.getDeliveryTypeTranslate(ORDER_TYPE.IMMEDIATE_DELIVERY)},
                {value: ORDER_TYPE.ROUNDTRIP_DELIVERY, title: this.offerService.getDeliveryTypeTranslate(ORDER_TYPE.ROUNDTRIP_DELIVERY)},
                {value: ORDER_TYPE.ROUNDTRIP_PICKUP, title: this.offerService.getDeliveryTypeTranslate(ORDER_TYPE.ROUNDTRIP_PICKUP)},
                {value: ORDER_TYPE.IMMEDIATE_PICKUP, title: this.offerService.getDeliveryTypeTranslate(ORDER_TYPE.IMMEDIATE_PICKUP)},
              ]
            }
          },
          valuePrepareFunction: (cell, row) => {
            return cell.map(item => this.offerService.getDeliveryTypeTranslate(item.code));
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        whatAreWeSelling: {
          title: this.translate.instant('common.offer.whatAreWeSelling'),
          type: 'text',
          filter: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        customEasyFee: {
          title: this.translate.instant('common.offer.easyFee(%)'),
          type: 'number',
          filter: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        }
      }
    };
  }

  public edit(event) {
    this.editAction.emit(event);
  }

}
