import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { OFFER_PAUSE, OPEN_TYPE } from '../../../common-shared/models/order-type';
import { CommonSubscriber } from '../../../common-shared/classes/common-subscriber';
import { InjectedOfferService, OfferModel } from '../../../common-offer/models/injectedOfferServiceModel';
import { InjectedOfferPlanningService } from '../../models/injectedOfferPlanningService';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment/moment';
import { forkJoin } from 'rxjs';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'lib-common-offer-pause-dialog',
  templateUrl: './common-offer-pause-dialog.component.html',
  styleUrls: ['./common-offer-pause-dialog.component.scss']
})
export class CommonOfferPauseDialogComponent extends CommonSubscriber implements OnInit {
  public isLoading = false;
  public isSubmitting = false;
  public pauseForm: FormGroup;
  public pauseTypesForSelect = [
    {
      value: OFFER_PAUSE.PAUSE_MINUTES,
      name: marker('common.offer.pauseDialog.pauseMinutes')
    },
    {
      value: OFFER_PAUSE.PAUSE_DATETIME,
      name: marker('common.offer.pauseDialog.pauseDateTime')
    },
    {
      value: OFFER_PAUSE.PAUSE_UNTIL_NEXT_OPENING_TIME,
      name: marker('common.offer.pauseDialog.pauseUntilNextOpeningTime')
    }
  ];
  public OFFER_PAUSE = OFFER_PAUSE;
  public OPEN_TYPE = OPEN_TYPE;
  public offer: OfferModel;
  public offerNextOpen;
  public now = moment();

  @Input() offerId;
  @Input() injectedOfferPlanningService: InjectedOfferPlanningService;
  @Input() injectedOfferService: InjectedOfferService;

  constructor(private dialogRef: NbDialogRef<CommonOfferPauseDialogComponent>) {
    super();
  }

  ngOnInit(): void {
    this.initPauseForm();
    this.getData(this.offerId);
  }

  private getData(offerId: string) {
    this.subs.push(
      forkJoin({
          offer: this.injectedOfferService.getSingleOffer(offerId),
          nextOpen: this.injectedOfferPlanningService.getOfferNextOpen(offerId),
        }
      ).subscribe(({offer, nextOpen}) => {
        this.isLoading = false;
        this.offer = offer;
        this.offerNextOpen = moment(nextOpen.nextOpen);
      })
    );
  }

  public initPauseForm() {
    this.pauseForm = new FormGroup({
      type: new FormControl('', [Validators.required]),
      minutes: new FormControl('', []),
      datetime: new FormControl('', []),
    });
  }

  submitPauseForm() {
    this.isSubmitting = true;
    const pauseData = this.pauseForm.getRawValue();
    if (pauseData.type === OFFER_PAUSE.PAUSE_MINUTES && pauseData.minutes != null) {
      pauseData.minutes = (pauseData.minutes.hours() * 60) + pauseData.minutes.minutes();
      delete pauseData.datetime;
    }
    if (pauseData.type === OFFER_PAUSE.PAUSE_DATETIME && pauseData.datetime != null) {
      pauseData.datetime = pauseData.datetime.toISOString();
      delete pauseData.minutes;
    }
    if (pauseData.type === OFFER_PAUSE.PAUSE_UNTIL_NEXT_OPENING_TIME) {
      delete pauseData.minutes;
      delete pauseData.datetime;
    }
    this.subs.push(this.injectedOfferPlanningService.updateOfferPause(this.offerId, pauseData)
      .subscribe(
        res => {
          this.dialogRef.close(true);
          this.isSubmitting = false;
        },
        err => {
          this.isSubmitting = false;
        }
      )
    );
  }

  updatePauseValidators(pauseType: OFFER_PAUSE) {
    switch (pauseType) {
      case OFFER_PAUSE.PAUSE_MINUTES:
        this.pauseForm.get('minutes').setValidators([Validators.required, this.minimalPauseTimeValidator()]);
        this.pauseForm.get('minutes').updateValueAndValidity();
        this.pauseForm.get('datetime').reset();
        this.pauseForm.get('datetime').clearValidators();
        this.pauseForm.get('datetime').updateValueAndValidity();
        break;
      case OFFER_PAUSE.PAUSE_DATETIME:
        this.pauseForm.get('datetime').setValidators([Validators.required, this.minimalPauseDateTimeValidator()]);
        this.pauseForm.get('datetime').updateValueAndValidity();
        this.pauseForm.get('minutes').reset();
        this.pauseForm.get('minutes').clearValidators();
        this.pauseForm.get('minutes').updateValueAndValidity();
        break;
      case OFFER_PAUSE.PAUSE_UNTIL_NEXT_OPENING_TIME:
        this.pauseForm.get('minutes').reset();
        this.pauseForm.get('minutes').clearValidators();
        this.pauseForm.get('minutes').updateValueAndValidity();
        this.pauseForm.get('datetime').reset();
        this.pauseForm.get('datetime').clearValidators();
        this.pauseForm.get('datetime').updateValueAndValidity();
        break;
    }
  }

  deletePause() {
    this.isSubmitting = true;
    this.subs.push(this.injectedOfferPlanningService.deleteOfferPause(this.offerId)
      .subscribe(
        res => {
          this.dialogRef.close(true);
          this.isSubmitting = false;
        },
        err => {
          this.isSubmitting = false;
        }
      )
    );
  }

  minimalPauseTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const time = control.value;
      if (time && time.hours() <= 0 && time.minutes() <= 0) {
        return {invalidMinPauseTime: true};
      }
      return null;
    };
  }

  minimalPauseDateTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateTime = control.value;
      if (dateTime && dateTime.isBefore(moment().add(1, 'minute'))) {
        return {invalidMinPauseDateTime: true};
      }
      return null;
    };
  }
}

