<div class="image_holder">
  <img src="/assets/images/easy-logo-dark.png">
</div>
<nb-card status="primary">
  <nb-card-header>
    <div class="text-center">
      {{headerTile}}
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="loginForm" class="row" autocomplete="on">
      <div class="col-12">
        <div class="form-group">
          <label for="email" class="label">{{'commonLib.form.email' | translate}}</label>
          <input [customErrors]="customErrors" placeholder="{{'commonLib.form.email' | translate}}" id="email"
                 class="w-100" nbInput
                 formControlName="email" type="email">
        </div>
        <div class="form-group">
          <label for="password" class="label">{{'commonLib.form.password' | translate}}</label>
          <input id="password" class="w-100" placeholder="{{'commonLib.form.password' | translate}}" nbInput
                 formControlName="password" type="password">
        </div>
      </div>
      <div class="col-12 text-center">
        <button
          type="submit"
          status="primary"
          [disabled]="loginForm.invalid"
          [nbSpinner]="submitIsLoading"
          (click)="submitForm()"
          nbButton> {{'commonLib.button.submit' | translate}}</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
