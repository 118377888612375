import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export const APP_SUPPORTED_LANGUAGES = ['en', 'fr'];

export function appInitializerFactory(
  translateService: TranslateService,
  localStorageLanguageKey: string,
  environment,
) {
  let languageSetObs: Observable<any>;
  return () => new Promise((resolve) => {
    /**
     * setting app default language language
     */
    // first set default language of app
    translateService.setDefaultLang('en');
    // if user have no information about language in local storage
    if (!localStorage.getItem(localStorageLanguageKey)) {
      // get supported languages from BE
      // check if browser default language is supported by app and set app language
      const browserLang = translateService.getBrowserLang();
      if (APP_SUPPORTED_LANGUAGES.some(singleLang => {
        if (browserLang === singleLang) {
          return true;
        }
      })) {
        // set language from browser and use it
        localStorage.setItem(localStorageLanguageKey, browserLang);
        languageSetObs = translateService.use(browserLang);
      } else {
        // if browser lang no supported by app set and use default lang
        localStorage.setItem(localStorageLanguageKey, translateService.defaultLang);
        languageSetObs = translateService.use(translateService.defaultLang);
      }
    } else {
      // TODO: find out language from request !
      // if have lang in localstorage, use it
      languageSetObs = translateService.use(localStorage.getItem(localStorageLanguageKey));
    }
    languageSetObs.toPromise().then(res => {
      resolve(true);
    });
  });
}
