import { OPTION_GROUP_TYPE, OptionGroup } from '../models/option-group';
import { BackendFilteringObject } from 'smart-table-backend-wrapper';
import { Observable } from 'rxjs';
import { CommonBackendPaginationResponse } from '../../../models/common-backend-pagination-response';
import { of } from 'rxjs';
import { CommonSelectItem } from '../../../models/common-select-item';
import { OptionItem } from '../models/option-item';
import { TranslateService } from '@ngx-translate/core';

export abstract class CommonOfferOptionGroupInjectedService {

  protected constructor(private translate: TranslateService) {
  }

  abstract getOptionGroupsPaginated(offerId: string,
                                    filteringObject?: BackendFilteringObject): Observable<CommonBackendPaginationResponse<OptionGroup>>;

  abstract getAllOptionGroups(offerId: string): Observable<OptionGroup[]>;

  abstract createOptionGroup(groupData: OptionGroup, offerId: string): Observable<OptionGroup>;

  abstract updateOptionGroup(groupId: string, groupData: OptionGroup): Observable<OptionGroup>;

  abstract deleteOptionGroup(groupId: string): Observable<undefined>;

  abstract updateOptionGroupAvailability(groupId: string, available: boolean): Observable<OptionGroup>;

  public getOptionGroupTypesSelect(): Observable<CommonSelectItem[]> {
    return of([{
      value: OPTION_GROUP_TYPE.SELECTIVE,
      name: this.translate.instant('common.offer_option_group.type.selective')
    }, {
      value: OPTION_GROUP_TYPE.CUMULATIVE,
      name: this.translate.instant('common.offer_option_group.type.cumulative')
    }, {
      value: OPTION_GROUP_TYPE.EXCLUSIVE,
      name: this.translate.instant('common.offer_option_group.type.exclusive')
    }]);
  }

  abstract getAllOptionItems(offerId: string): Observable<OptionItem[]>;

  abstract getOptionItemsPaginated(offerId: string,
                                   filteringObject?: BackendFilteringObject): Observable<CommonBackendPaginationResponse<OptionItem>>;

  abstract updateOptionItemAvailability(itemId: string, available: boolean): Observable<OptionItem>;

  abstract createOptionItem(itemData: OptionGroup, offerId: string): Observable<OptionItem>;

  abstract updateOptionItem(itemId: string, itemData: OptionItem): Observable<OptionItem>;

  abstract deleteOptionItem(itemId: string): Observable<undefined>;
}
