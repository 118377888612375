<form [nbSpinner]="formIsLoading" [formGroup]="productForm">
  <div class="row">
    <div class="col-md-auto photo_column">
      <div>
        <label>{{'common.offer.product.form.productPhoto' | translate}}</label>
        <img *ngIf="productImageUrl" class="w-100 mx-auto mb-3" [src]="productImageUrl" alt="">
        <div class="text-center">
          <button size="small"
                  outline status="primary"
                  [nbSpinner]="photoUploadIsLoading"
                  nbSpinnerStatus="primary"
                  (click)="uploadPhotoInput.click()"
                  nbButton><span
            *ngIf="!productImageUrl; else changePhoto"> {{'common.offer.product.form.uploadPhoto' | translate}}</span>
            <ng-template #changePhoto>
          <span>
              {{'common.offer.product.form.changePhoto' | translate}}
          </span>
            </ng-template>
          </button>
        </div>
      </div>
      <input (change)="uploadPhoto($event)" #uploadPhotoInput class="d-none" type="file" accept="image/*">
    </div>
    <div class="col-md">
      <div class="row">
        <div class="col-12">
          <label for="isAvailable">{{ 'common.offer.product.form.isAvailable' | translate }}</label>
          <nb-select fullWidth
                     id="isAvailable"
                     formControlName="isAvailable"
                     [status]="productForm.get('isAvailable').touched && productForm.get('isAvailable').invalid ? 'danger' : 'basic'"
                     [placeholder]="'common.offer.product.form.isAvailable' | translate">
            <nb-option [value]="true">{{ 'common.offer.product.form.yes' | translate }}</nb-option>
            <nb-option [value]="false">{{ 'common.offer.product.form.no' | translate }}</nb-option>
          </nb-select>
        </div>
        <div class="col-12 col-md-6 my-2">
          <label for="name">{{ 'common.offer.product.form.name' | translate }}</label>
          <input type="text"
                 formControlName="name"
                 nbInput
                 id="name"
                 fullWidth
                 shape="rectangle"
                 [status]="productForm.get('name').touched && productForm.get('name').invalid ? 'danger' : 'basic'"
                 [placeholder]="'common.offer.product.form.name' | translate">
        </div>
        <div class="col-12 col-md-6 my-2">
          <div class="row no-gutters flex-column">
            <label for="price">{{ 'common.offer.product.form.price' | translate }}</label>
            <nb-form-field class="w-100">
              <nb-icon nbSuffix icon="euro-sign"></nb-icon>
              <input id="price" formControlName="price"
                     [status]="productForm.get('price').touched && productForm.get('price').invalid ? 'danger' : 'basic'"
                     placeholder="{{'common.offer.product.form.price' | translate}}" nbInput
                     type="number">
            </nb-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6 my-2">
          <div class="row no-gutters flex-column">
            <label for="vat">{{ 'common.offer.product.form.vat' | translate }}</label>
            <nb-form-field class="w-100">
              <nb-icon nbSuffix icon="percent"></nb-icon>
              <input id="vat" formControlName="vat"
                     class="w-100"
                     [status]="productForm.get('vat').touched && productForm.get('vat').invalid ? 'danger' : 'basic'"
                     placeholder="{{'common.offer.product.form.vat' | translate}}" nbInput
                     type="number">
            </nb-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6 my-2">
          <label for="menuCategoryId">{{ 'common.offer.product.form.categoryId' | translate }}</label>
          <nb-select fullWidth
                     id="menuCategoryId"
                     formControlName="menuCategoryId"
                     [nbSpinner]="categoriesIsLoading"
                     [status]="productForm.get('menuCategoryId').touched && productForm.get('menuCategoryId').invalid ? 'danger' : 'basic'"
                     [placeholder]="'common.offer.product.form.categoryId' | translate">
            <nb-option [value]="item.id" *ngFor="let item of categories">{{ item.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-12 col-md-6 my-2">
          <label for="description">{{ 'common.offer.product.form.description' | translate }}</label>
          <input type="text"
                 formControlName="description"
                 nbInput
                 id="description"
                 fullWidth
                 shape="rectangle"
                 [status]="productForm.get('description').touched && productForm.get('description').invalid ? 'danger' : 'basic'"
                 [placeholder]="'common.offer.product.form.description' | translate">
        </div>
        <div class="col-12 col-md-6 my-2">
          <label for="ingredients">{{ 'common.offer.product.form.ingredients' | translate }}</label>
          <input type="text"
                 formControlName="ingredients"
                 nbInput
                 id="ingredients"
                 fullWidth
                 shape="rectangle"
                 [status]="productForm.get('ingredients').touched && productForm.get('ingredients').invalid ? 'danger' : 'basic'"
                 [placeholder]="'common.offer.product.form.ingredients' | translate">
        </div>
        <div class="col-12 col-md-6 my-2">
          <label for="allergens">{{ 'common.offer.product.form.allergens' | translate }}</label>
          <nb-select fullWidth
                     id="allergens"
                     [nbSpinner]="allergensIsLoading"
                     multiple
                     formControlName="allergens"
                     [status]="productForm.get('allergens').touched && productForm.get('allergens').invalid ? 'danger' : 'basic'"
                     [placeholder]="'common.offer.product.form.allergens' | translate">
            <nb-option [value]="item.code"
                       *ngFor="let item of allergens">{{ item.description[translateService.currentLang].name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-12 col-md-6 my-2">
          <label for="menuOptionGroups">{{ 'common.offer.product.form.menuOptions' | translate }}</label>
          <nb-select fullWidth
                     id="menuOptionGroups"
                     multiple
                     [nbSpinner]="optionsGroupIsLoading"
                     formControlName="menuOptionGroups"
                     [status]="productForm.get('menuOptionGroups').touched && productForm.get('menuOptionGroups').invalid ? 'danger' : 'basic'"
                     [placeholder]="'common.offer.product.form.menuOptions' | translate">
            <nb-option [value]="item.id" *ngFor="let item of groups">{{ item.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-12 pt-2">
          <div class="row justify-content-end">
            <div class="col-auto">
              <button nbButton status="danger"
                      *ngIf="isEdit"
                      class="ml-3"
                      [nbSpinner]="deleteIsLoading"
                      nbSpinnerStatus="danger"
                      outline
                      (click)="deleteProduct()">
                {{'commonLib.button.delete' | translate}}
              </button>
            </div>
            <div class="col-auto">
              <button nbButton status="primary"
                      [nbSpinner]="submitIsLoading"
                      nbSpinnerStatus="primary"
                      (click)="submitForm()"
                      [disabled]="productForm.invalid">
                {{'commonLib.button.submit' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>
