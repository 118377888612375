<lib-common-form-field-info
  [text]="'common.offer_option_item.desc_info'| translate">
</lib-common-form-field-info>
<stbw-grid [filteringObject]="filteringObj"
           [settings]="settings"
           [source]="source"
           [isLoading]="loading"
           (create)="handleModalAction()"
           (edit)="handleModalAction($event)"
           (delete)="delete($event)"
           (paginatorChangeEvent)="pageChanged($event)"
>
</stbw-grid>
