import { Observable } from 'rxjs';

export abstract class InjectOfferCategoryService {

  abstract deleteMenuCategory(offerId, categoryId);

  abstract getOfferCategories(offerId): Observable<any>;

  abstract updateMenuCategory(offerId, categoryId, data);

  abstract updateMenuCategoriesPosition(offerId, orderedCategories);

  abstract createMenuCategory(offerId, data);

}
