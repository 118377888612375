import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonOfferOptionGroupInjectedService } from '../../services/common-offer-option-group-injected-service';
import { OPTION_GROUP_TYPE, OptionGroup } from '../../models/option-group';
import { CommonSubscriber } from '../../../common-shared/classes/common-subscriber';
import { CommonSelectItem } from '../../../../models/common-select-item';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NbDialogRef } from '@nebular/theme';
import { OptionItem } from '../../models/option-item';

@Component({
  selector: 'lib-common-offer-merchant-offer-option-group-detail',
  templateUrl: './common-offer-option-group-detail.component.html',
  styleUrls: ['./common-offer-option-group-detail.component.scss']
})
export class CommonOfferOptionGroupDetailComponent extends CommonSubscriber implements OnInit {
  filteredOptions$: Observable<CommonSelectItem[]>;
  public optionGroupForm: FormGroup;
  public isSubmitting: boolean;
  public types: CommonSelectItem[] = [];
  public optionItems: CommonSelectItem[] = [];
  @Input() offerId: string;
  @Input() commonOfferOptionGroupInjectedService: CommonOfferOptionGroupInjectedService;
  @Input() groupData: OptionGroup;

  @ViewChild('autoInput') input;

  constructor(private fb: FormBuilder, private dialogRef: NbDialogRef<CommonOfferOptionGroupDetailComponent>) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(this.commonOfferOptionGroupInjectedService.getOptionGroupTypesSelect().subscribe(res => {
        this.types = res;
      })
    );
    this.subs.push(this.commonOfferOptionGroupInjectedService.getAllOptionItems(this.offerId).subscribe(res => {
        this.optionItems = res.map(item => {
          return {
            name: item.name,
            value: item.id
          };
        });
        this.onChange();
      })
    );
    this.initForm(this.groupData);
  }

  private initForm(groupData?: OptionGroup) {
    this.optionGroupForm = this.fb.group({
      name: ['', [Validators.required]],
      visibleName: ['', [Validators.required]],
      type: ['', [Validators.required]],
      items: [[], [Validators.required]]
    });
    console.log(this.groupData);
    if (groupData) {
      this.handleFormControlsByType(groupData.type);
      this.optionGroupForm.patchValue(groupData);
      console.log(this.optionGroupForm.getRawValue());
    }
  }

  onSelectionChange(e: CommonSelectItem) {
    const includes = this.optionGroupForm.get('items').value.some(option => option.id === e.value);
    if (!includes) {
      const actualValues = this.optionGroupForm.get('items').value;
      actualValues.push({
        id: e.value,
        name: e.name
      });
      this.optionGroupForm.get('items').setValue(actualValues);
    }
    this.input.nativeElement.value = '';
    this.onChange();
  }

  removeItem(index) {
    const actualitems = this.optionGroupForm.get('items').value.splice(index, 1);
  }

  public handleFormControlsByType(type: OPTION_GROUP_TYPE) {
    console.log('.loGE');
    if (type === OPTION_GROUP_TYPE.CUMULATIVE || type === OPTION_GROUP_TYPE.SELECTIVE) {
      this.optionGroupForm.addControl('minCount',
        this.fb.control([0, [Validators.required, Validators.min(1)]]));
      this.optionGroupForm.addControl('maxCount',
        this.fb.control([0, [Validators.required, Validators.min(1)]]));
    } else {
      this.optionGroupForm.removeControl('minCount');
      this.optionGroupForm.removeControl('maxCount');
    }
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  getFilteredOptions(value: string): Observable<CommonSelectItem[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  private filter(value: string): CommonSelectItem[] {
    const filterValue = value.toLowerCase();
    return this.optionItems.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue.toLowerCase()));
  }

  submit() {
    const data = this.optionGroupForm.getRawValue();
    data.items = data.items.map(item => item.id);
    console.log(data);
    this.isSubmitting = true;
    if (this.groupData) {
      this.subs.push(this.commonOfferOptionGroupInjectedService.updateOptionGroup(this.groupData.id, data).subscribe(res => {
          this.dialogRef.close(true);
        })
      );
    } else {
      this.subs.push(this.commonOfferOptionGroupInjectedService.createOptionGroup(data, this.offerId).subscribe(res => {
          this.dialogRef.close(true);
        })
      );
    }
  }

  autocompleteViewHandle(value: OptionItem | string) {
    console.log(value);
    if (value instanceof Object) {
      return value.name;
    } else {
      return value;
    }
  }
}
