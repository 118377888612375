<nb-card>
  <nb-card-header>
    <div *ngIf="groupData; else createNewTitle">
      {{ 'common.offer_option_group.detail.updateExisting' | translate }}: {{groupData.name}}
    </div>
    <ng-template #createNewTitle>
      {{ 'common.offer_option_group.detail.createNew' | translate }}
    </ng-template>
  </nb-card-header>
  <nb-card-body>
    <div class="row" [formGroup]="optionGroupForm">
      <div class="col-md-6">
        <div class="form-group">
          <label for="name" class="required d-inline-block">
            {{ 'common.offer_option_group.detail.name' | translate }}
          </label>
          <lib-common-form-field-info
            [showInTooltip]="true"
            [text]="'common.offer_option_group.detail.name_tooltip'| translate">
          </lib-common-form-field-info>
          <input type="text"
                 formControlName="name"
                 nbInput
                 id="name"
                 fullWidth
                 [placeholder]="'common.offer_option_group.detail.name' | translate">
        </div>
        <div class="form-group">
          <label for="visibleName"
                 class="required d-inline-block">{{ 'common.offer_option_group.detail.visibleName' | translate }}</label>
          <lib-common-form-field-info
            [showInTooltip]="true"
            [text]="'common.offer_option_group.detail.visibleName_tooltip'| translate">
          </lib-common-form-field-info>
          <input type="text"
                 formControlName="visibleName"
                 nbInput
                 id="visibleName"
                 fullWidth
                 [placeholder]="'common.offer_option_group.detail.visibleName' | translate">
        </div>
        <div class="form-group">
          <label class="required d-inline-block">
            {{ 'common.offer_option_group.detail.type' | translate }}
          </label>
          <lib-common-form-field-info
            [showInTooltip]="true"
            [text]="'common.offer_option_group.detail.type_tooltip'| translate">
          </lib-common-form-field-info>
          <nb-select placeholder="" formControlName="type"
                     fullWidth
                     (selectedChange)="handleFormControlsByType($event)">
            <nb-option *ngFor="let type of types"
                       [value]="type.value">
              {{type.name}}
            </nb-option>
          </nb-select>
        </div>
        <div class="row" *ngIf="optionGroupForm.controls['minCount'] && optionGroupForm.controls['maxCount']">
          <div class="col-6">
            <div class="form-group">
              <label for="minCount"
                     class="required">{{ 'common.offer_option_group.detail.minCount' | translate }}</label>
              <input type="number"
                     formControlName="minCount"
                     nbInput
                     id="minCount"
                     fullWidth
                     [placeholder]="'common.offer_option_group.detail.minCount' | translate">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="maxCount"
                     class="required">{{ 'common.offer_option_group.detail.maxCount' | translate }}</label>
              <input type="number"
                     formControlName="maxCount"
                     nbInput
                     id="maxCount"
                     fullWidth
                     [placeholder]="'common.offer_option_group.detail.maxCount' | translate">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="maxCount"
                 class="required">{{ 'common.offer_option_group.detail.addOptionItem' | translate }}</label>
          <nb-form-field>
            <nb-icon nbPrefix icon="search"></nb-icon>
            <input #autoInput
                   nbInput
                   type="text"
                   fullWidth
                   (input)="onChange()"
                   placeholder=""
                   [nbAutocomplete]="auto"/>
          </nb-form-field>
          <nb-autocomplete #auto
                           [handleDisplayFn]="autocompleteViewHandle"
                           (selectedChange)="onSelectionChange($event)">
            <nb-option *ngFor="let option of filteredOptions$ | async"
                       [value]="option">
              {{ option.name }}
            </nb-option>
          </nb-autocomplete>
        </div>
        <div class="items">
          <div class="caption">
            {{ 'common.offer_option_group.detail.included_products' | translate }}:
          </div>
          <div class="item row no-gutters py-1 align-items-center"
               *ngFor="let item of optionGroupForm.controls['items'].value; let i = index">
            <div class="col">
              <!--            TODO ADD PRODUCTS IMAGES-->
              {{item.name}}
            </div>
            <div class="col-auto">
              <button
                nbButton size="tiny" (click)="removeItem(i)" outline status="danger">
                <!--              <nb-icon icon="trash"></nb-icon>-->
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button nbButton
                type="submit" status="primary"
                [disabled]="optionGroupForm.invalid"
                [nbSpinner]="isSubmitting"
                (click)="submit()">
          {{ 'common.offer_option_group.detail.submit' | translate }}
        </button>
      </div>
    </div>


  </nb-card-body>
</nb-card>
