export interface CustomerAddress {
  id: string;
  placeName: string;
  // CUSTOMER IS ACTIVE ADDRESS
  active?: boolean;
  address: GoogleAutocompleteAddress;
  isArea: boolean;
  deliveryHelpData: {
    apartment: string;
    building: string;
    code: string;
    elevator: string;
    floor: string;
    notes: string;
  };
  lat: number;
  long: number;
}

export interface GoogleAutocompleteAddress {
  address: string;
  autocompletePlace: any;
  autocomplete_value: string;
  city: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  zip_code: string;
}


export interface UserModel {
  adminRoles: ADMIN_ROLES;
  // COMMON
  type?: USER_ROLE;
  id?: string;
  email: string;
  name: string;
  surname: string;
  password?: string;
  passwordChangeTime?: Date;
  phoneNumber?: string;
  phoneNumberActivationCode?: string;
  isCGUAccepted?: boolean;
  CGUAcceptedAt?: Date;
  areaId?: string;
  role: USER_ROLE;
  // CUSTOMER
  lastDeliveryAddressId?: string;
  privacyData?: any;
  defaultAddress?: any;
  addresses?: any[];
  loyaltyPoints?: number;
  countOfActiveCoupons?: number;
  countOfIncidents?: number;

  // DELIVERY
  photo?: string;
  deliveryCapacity?: number;
  rating?: number;
  numberOfReviews?: number;
  isRoundTrip?: boolean;
  isImmediate?: boolean;
  isPreferred?: boolean;
  isAvailable?: boolean;
  partnerType?: PartnerType;
  deliveryGuyStatusId?: string;

  // MERCHANT
  assortment: MERCHANT_ASSORTMENT;
  addressData?: any;
  address?: any;
  siteId?: string;
  site?: any;
  merchantName?: string;
  isRatingOverride?: boolean;
  ratingOverride?: number;
  minOrder?: number;
  isAutoPlanning?: boolean;
  vacationStart?: Date;
  vacationEnd?: Date;
  description?: string;
  priceRating?: 1 | 2 | 3 | 4;
  isPremium?: boolean;
  headerPhotoPath?: string;
  orderPrefix?: string;
  areas: any;
}

export interface UserResponse {
  refreshToken: string;
  accessToken: string;
  user: UserModel;
}

export enum PartnerType {
  /**
   * Working alone
   */
  FREELANCER = 'freelancer',

  /**
   * Working more than one
   */
  CONTRACTED_PARTNER = 'contracted_partner'
}

export enum USER_ROLE {
  CUSTOMER = 'customer',
  MERCHANT = 'merchant',
  DELIVERY = 'delivery',
  ADMIN = 'admin'
}

export enum ADMIN_ROLES {
  ROLE_REPORTS = 'ROLE_REPORTS'
}

export enum MERCHANT_ASSORTMENT {
  RESTAURANT = 'restaurant',
  SUPERMARKET = 'supermarket'
}
