import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonAuthLoginComponent } from './components/common-auth-login/common-auth-login.component';
import { NbButtonModule, NbCardModule, NbInputModule, NbSpinnerModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { CommonInputValidatorsModule } from '../common-input-validators/common-input-validators.module';
import { CommonFormModule } from '../common-form/common-form.module';



@NgModule({
  declarations: [
    CommonAuthLoginComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    TranslateModule,
    CommonFormModule,
    NbInputModule,
    NbButtonModule,
    NbSpinnerModule,
    CommonInputValidatorsModule
  ], exports : [
    CommonAuthLoginComponent
  ]
})
export class CommonAuthModule { }
