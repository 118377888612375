import { Component, Input, OnInit } from '@angular/core';
import { CommonOfferOptionGroupInjectedService } from '../../services/common-offer-option-group-injected-service';
import { FilteringWrapper } from 'smart-table-backend-wrapper';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogService } from '@nebular/theme';
import { CommonGridToggleComponent } from '../../../common-grid-component/components/common-grid-toggle/common-grid-toggle.component';
import { CommonOfferOptionItemDetailComponent } from '../common-offer-option-item-detail/common-offer-option-item-detail.component';
import {COMMON_GRID_ACTION_BUTTONS, fromCentsToEuro} from '../../../../config';

@Component({
  selector: 'lib-common-offer-option-items-list',
  templateUrl: './common-offer-option-items-list.component.html',
  styleUrls: ['./common-offer-option-items-list.component.scss']
})
export class CommonOfferOptionItemsListComponent extends FilteringWrapper implements OnInit {
  @Input() offerId: string;
  @Input() commonOfferOptionGroupInjectedService: CommonOfferOptionGroupInjectedService;

  constructor(private translate: TranslateService,
              private dialogService: NbDialogService) {
    super();
  }

  ngOnInit(): void {
    this.initGridCustomization();
  }

  loadData(): void {
    this.loading = true;
    this.commonOfferOptionGroupInjectedService.getOptionItemsPaginated(this.offerId, this.filteringObj)
      .subscribe(res => {
        this.filteringObj.setPager(
          res.meta.currentPage,
          res.meta.pageCount,
          res.meta.perPage,
          res.meta.total
        );
        this.source.load(res.data).then(() => {
          this.loading = false;
        });
      }, err => {
        this.loading = false;
      });
  }

  setColumns(): void {
    this.settings = {
      pager: false,
      localStorageId: 'offer-option-items',
      mode: 'external',
      ...COMMON_GRID_ACTION_BUTTONS,
      actions: {
        columnTitle: this.translate.instant('common.offer_option_item.grid.actions'),
        add: true,
        delete: true
      },
      columns: {
        id: {
          title: this.translate.instant('common.offer_option_item.grid.id'),
          type: 'text',
          filter: false,
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        name: {
          title: this.translate.instant('common.offer_option_item.grid.name'),
          type: 'html',
          valuePrepareFunction: (cell, row) => {
            return `<b>${cell}</b>`;
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'price*priceWithVat': {
          title: this.translate.instant('common.offer_option_item.grid.priceWithVat'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return fromCentsToEuro(row.price.priceWithVat);
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'price*priceExcludingVat': {
          title: this.translate.instant('common.offer_option_item.grid.priceExcludingVat'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return fromCentsToEuro(row.price.priceExcludingVat);
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        'price*vat': {
          title: this.translate.instant('common.offer_option_item.grid.vat'),
          type: 'text',
          filter: false,
          valuePrepareFunction: (cell, row) => {
            return row.price.vat;
          },
          compareFunction: (cell, row) => {
          },
          filterFunction: (cell, row) => {
            return 1;
          }
        },
        isAvailable: {
          title: this.translate.instant('common.offer_option_item.grid.availability'),
          type: 'custom',
          renderComponent: CommonGridToggleComponent,
          onComponentInitFunction: (instance) => {
            instance.key = 'isAvailable';
            instance.changeMethod.subscribe(event => {
              this.loading = true;
              this.commonOfferOptionGroupInjectedService.updateOptionItemAvailability(event.data.id, !event.data.isAvailable)
                .subscribe((res) => {
                  console.log(res);
                  this.loading = false;
                }, () => this.loading = false);
            });
          }
        }
      }
    };
  }

  async handleModalAction(rowData?: any) {
    const dialogDataOnClose = await this.dialogService.open(CommonOfferOptionItemDetailComponent, {
      context: {
        itemData: rowData?.data,
        offerId: this.offerId,
        commonOfferOptionGroupInjectedService: this.commonOfferOptionGroupInjectedService
      }
    }).onClose.toPromise();
    console.log(dialogDataOnClose);
    if (dialogDataOnClose) {
      this.refreshData();
    }
  }

  delete(rowData?: any) {
    if (rowData.data.id) {
      this.loading = true;
      this.subs.push(
        this.commonOfferOptionGroupInjectedService.deleteOptionItem(rowData.data.id).subscribe(res => {
          this.refreshData();
        }, error => this.loading = false)
      );
    }
  }
}
