<ng-container *ngIf="showInTooltip;else contentInfoWithHtml">
  <nb-icon
    class="field_info aligned_center ml-1"
    status="info"
    icon="info-circle"
    [nbPopover]="popoverContent"
    [nbPopoverTrigger]="popoverTrigger"></nb-icon>
</ng-container>

<ng-template #contentInfoWithHtml>
  <div class="pt-1">
    <nb-alert status="basic" class="mb-1">
      <div class="d-flex">
        <nb-icon
          class="field_info mr-1 pt-1"
          status="info"
          icon="info-circle"></nb-icon>
        <div [innerHTML]="text">
        </div>
      </div>
    </nb-alert>
  </div>
</ng-template>

<ng-template #popoverContent>
  <div class="p-2 popover_container" [innerHTML]="text">
  </div>
</ng-template>
