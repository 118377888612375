import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-common-day-night-switch',
  templateUrl: './common-day-night-switch.component.html',
  styleUrls: ['./common-day-night-switch.component.scss']
})
export class CommonDayNightSwitchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
