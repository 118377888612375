import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { AuthService } from './services/auth.service';
import { CommonBackbuttonHistoryService } from 'common-lib';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'merchant';

  constructor(private languageService: LanguageService,
              private authService: AuthService,
              private iconLibraries: NbIconLibraries,
              private commonBackbuttonHistoryService: CommonBackbuttonHistoryService) {
  }

  ngOnInit(): void {
    this.authService.initUser();
    this.iconLibraries.registerFontPack('solid',
      {packClass: 'fas', iconClassPrefix: 'fa'}
    );
    /*    this.iconLibraries.registerFontPack('regular',
          {packClass: 'far', iconClassPrefix: 'fa'}
        );*/
    this.iconLibraries.setDefaultPack('solid');
  }
}
